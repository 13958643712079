<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="profiles"
      :single-select="singleSelect"
      item-key="id"
      show-select
      class="elevation-1"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
      :search="search"
    >
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        color="#FD5C01"
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";

@Component
export default class TableComponent extends Vue {
  @Prop({ type: Array, required: true }) profiles!: unknown[];
  @Prop() readonly search!: string;

  // Page
  page = 1;
  pageCount = 0;
  itemsPerPage = 10;
  // Select
  singleSelect = true;
  selected = [];
  // Data
  headers = [
    { text: "Codigo", align: "center", sortable: false, value: "code" },
    {
      text: "Descripción",
      align: "center",
      sortable: false,
      value: "description",
    },
    {
      text: "Abreviatura",
      align: "center",
      sortable: false,
      value: "abbreviation",
    },
    { text: "Habilitado", align: "center", sortable: false, value: "status" },
  ];

  @Watch("selected")
  onSelectedChange(
    newValue: unknown
    //, oldValue: unknown
  ) {
    this.emitSelected(newValue);
  }

  @Emit("selected")
  emitSelected(selected: unknown) {
    return selected;
  }
}
</script>
