// Funcional
// import Vue from "vue";
// import Vuex from "vuex";

// Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {},
//   getters: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });
// store/index.js
import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/compat/app";
import { db } from "../firebase";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
  },
  actions: {
    fetchCurrentUser({ commit }) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const userDoc = await db
            .collection("usuarios")
            .where("uid", "==", user.uid)
            .get();
          userDoc.forEach((doc) => {
            commit("setCurrentUser", doc.data());
          });
        } else {
          commit("setCurrentUser", null);
        }
      });
    },
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
  },
});
