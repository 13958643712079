// import Vue from "vue";
// import VueRouter, { RouteConfig } from "vue-router";
// import HomeView from "../views/HomeView.vue";
// import LoginView from "../views/LoginView.vue";
// import UsersView from "../views/UsersView.vue";
// import ProfilesView from "../views/ProfilesView.vue";
// import ContractsView from "../views/ContractsView.vue";
// import MonthlySalesRegistrationView from "../views/MonthlySalesRegistrationView.vue";
// import Reports from "../views/ReportsView.vue";
// import MallsView from "../views/MallsView.vue";
// import CountriesView from "../views/CountriesView.vue";

// Vue.use(VueRouter);

// const routes: Array<RouteConfig> = [
//   {
//     path: "/",
//     name: "home",
//     component: HomeView,
//   },
//   {
//     path: "/login",
//     name: "login",
//     component: LoginView,
//   },
//   {
//     path: "/users",
//     name: "users",
//     component: UsersView,
//   },
//   {
//     path: "/countries",
//     name: "countries",
//     component: CountriesView,
//   },
//   {
//     path: "/malls",
//     name: "malls",
//     component: MallsView,
//   },
//   {
//     path: "/profiles",
//     name: "profiles",
//     component: ProfilesView,
//   },
//   {
//     path: "/contracts",
//     name: "contracts",
//     component: ContractsView,
//   },
//   {
//     path: "/monthlysalesregistration",
//     name: "monthlysalesregistration",
//     component: MonthlySalesRegistrationView,
//   },
//   {
//     path: "/reports",
//     name: "reports",
//     component: Reports,
//   },
//   {
//     path: "/about",
//     name: "about",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//       import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
//   },
// ];

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

// export default router;
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import firebase from "firebase/compat/app"; // Importar el módulo de Firebase

// Importar las vistas
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import UsersView from "../views/UsersView.vue";
import ProfilesView from "../views/ProfilesView.vue";
import ContractsView from "../views/ContractsView.vue";
import MonthlySalesRegistrationView from "../views/MonthlySalesRegistrationView.vue";
import ReportsView from "../views/ReportsView.vue"; // Renombrar la importación de la vista de informes
import MallsView from "../views/MallsView.vue";
import CountriesView from "../views/CountriesView.vue";
import AboutView from "../views/AboutView.vue"; // Importar la vista de "AboutView"

Vue.use(VueRouter);

// Definir las rutas
const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true }, // Requiere autenticación
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: { requiresAuth: true }, // Requiere autenticación
  },
  {
    path: "/countries",
    name: "countries",
    component: CountriesView,
    meta: { requiresAuth: true }, // Requiere autenticación
  },
  {
    path: "/malls",
    name: "malls",
    component: MallsView,
    meta: { requiresAuth: true }, // Requiere autenticación
  },
  {
    path: "/profiles",
    name: "profiles",
    component: ProfilesView,
    meta: { requiresAuth: true }, // Requiere autenticación
  },
  {
    path: "/contracts",
    name: "contracts",
    component: ContractsView,
    meta: { requiresAuth: true }, // Requiere autenticación
  },
  {
    path: "/monthlysalesregistration",
    name: "monthlysalesregistration",
    component: MonthlySalesRegistrationView,
    meta: { requiresAuth: true }, // Requiere autenticación
  },
  {
    path: "/reports",
    name: "reports",
    component: ReportsView,
    meta: { requiresAuth: true }, // Requiere autenticación
    props: true,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Definir un guardia de ruta para la autenticación
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const currentUser = firebase.auth().currentUser;

  if (requiresAuth && !currentUser) {
    // Si la ruta requiere autenticación y el usuario no está autenticado
    next({ name: "login" }); // Redirigir a la página de inicio de sesión
  } else {
    next(); // Continuar navegando
  }
});

// Agregar un guardia de ruta después de la resolución de la promesa de autenticación
firebase.auth().onAuthStateChanged(() => {
  // Recargar la página después de iniciar sesión para asegurar que todos los componentes se carguen correctamente
  if (router.currentRoute.path === "/home") {
    window.location.reload();
  }
});

export default router;
