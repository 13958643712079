<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="1200" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-import</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title>Importar</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-file-input
              accept=".xlsx"
              label="Excel"
              v-model="archivo"
              @change="handleFileUpload($event)"
            ></v-file-input>
          </v-container>
        </v-card-text>

        <v-card-text v-if="items.length > 0">
          <v-container>
            <v-data-table :headers="header" :items="items"></v-data-table>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="#FD5C01"
            @click="uploadItemsToFirestore"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Usuarios Agregados con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit } from "vue-property-decorator";
import * as XLSX from "xlsx";

import { db } from "../../firebase";
// import firebase from "firebase/compat/app";

@Component
export default class ImportComponent extends Vue {
  archivo: Blob | null = null;
  header = [
    { text: "Interno", value: "code" },
    { text: "Usuario", value: "name" },
    { text: "Correo", value: "email" },
    { text: "Nombres", value: "names" },
    { text: "Apellidos", value: "surnames" },
    { text: "Teléfono", value: "phones" },
    { text: "Estado", value: "status" },
    { text: "Cambiar Clave", value: "changepassword" },
  ];

  // eslint-disable-next-line
  items: any[] = [];

  confirmUpload = false;
  dialog = false;
  snackbar = false;
  snackbarTimeout = 3000;
  uid = "";

  // eslint-disable-next-line
  handleFileUpload(file: any) {
    if (file) {
      this.readFile(file);
    }
  }

  readFile(file: Blob) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target?.result;
      if (binaryString) {
        this.parseExcel(binaryString);
      }
    };
    reader.readAsBinaryString(file);
  }

  // eslint-disable-next-line
  parseExcel(binaryString: any) {
    const workbook = XLSX.read(binaryString, { type: "binary" });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 2 });

    // eslint-disable-next-line
    this.items = data.map((row: any) => ({
      code: row[0].toString(),
      name: row[1].toUpperCase(),
      email: row[2].toUpperCase(),
      names: row[3].toUpperCase(),
      surnames: row[4].toUpperCase(),
      phones: row[5].toUpperCase(),
      status: row[6].toUpperCase(),
      changepassword: row[7].toUpperCase(),
    }));
  }

  async uploadItemsToFirestore() {
    if (this.items.length > 0) {
      await Promise.all(
        this.items.map(async (item) => {
          // if (
          //   item.code &&
          //   item.name &&
          //   item.email &&
          //   item.status &&
          //   item.names &&
          //   item.surnames &&
          //   item.phones &&
          //   item.changepassword
          // ) {
          // const userCredential = await firebase
          //   .auth()
          //   .createUserWithEmailAndPassword(item.email, "FloridaGo2024");
          // const user = userCredential.user;
          // const uid = user?.uid;
          // if (uid) {
          // let uid = "";
          try {
            //await this.addUser(item.email);
            this.addCollection(item);
          } catch (error) {
            console.error("Error al obtener el UID del usuario:", error);
            // Manejar el error según sea necesario
          }

          // console.log(uid);

          // }
          // try {
          //   firebase
          //     .auth()
          //     .createUserWithEmailAndPassword(item.email, "FloridaGo2024")
          //     .then(
          //       (user) => {
          //         this.uid = user.user?.uid || "";
          //         this.addCollection(this.uid, item);
          //       },
          //       (err) => {
          //         alert(err);
          //       }
          //     );
          // } catch (error) {
          //   console.error("Error al subir elemento a Firestore:", error);
          // }
          // } else {
          //   console.log(
          //     "Falta uno o más campos necesarios en un elemento:",
          //     item
          //   );
          // }
        })
      );

      this.emitConfirmUpload(true);
      this.snackbar = true;
      this.dialog = false;
      this.items = [];
    } else {
      console.log("No hay elementos para subir");
    }
  }
  // eslint-disable-next-line
  addCollection(item: any) {
    db.collection("usuarios").add({
      code: item.code,
      name: item.name,
      email: item.email,
      password: "FloridaGo2024",
      status: item.status,
      names: item.names,
      surnames: item.surnames,
      phones: item.phones,
      changepassword: item.changepassword,
      contractsSelected: [],
      profileSelected: [],
      uid: "",
    });
  }

  // async addUser(email: string): Promise<string> {
  //   try {
  //     const userCredential = await firebase
  //       .auth()
  //       .createUserWithEmailAndPassword(email, "FloridaGo2024");

  //     const uid = userCredential.user?.uid;

  //     if (!uid) {
  //       throw new Error("No se pudo obtener el ID del usuario.");
  //     }

  //     return uid;
  //   } catch (error) {
  //     console.error("Error al crear el usuario:", error);
  //     throw error;
  //   }
  // }

  @Emit("confirmUpload")
  emitConfirmUpload(confirmUpload: boolean) {
    return confirmUpload;
  }
}
</script>
