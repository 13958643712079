<template>
  <v-container fluid>
    <v-row dense class="ma-4">
      <v-col>
        <h1>Informes de Registraciones</h1>
      </v-col>
    </v-row>

    <!-- Cuadro Botones -->
    <v-row dense class="ma-4">
      <!-- Campo de busqueda -->
      <v-col>
        <h3>Filtros de Informe de Registraciones</h3>
      </v-col>
      <v-col cols="12" md="6"></v-col>
      <!-- Importar Excel -->
      <v-col
        cols="auto"
        md="auto"
        v-if="currentUser.profileSelected[0] === '2'"
      >
        <import-xls-component
          :eventsSelected="eventsSelected"
        ></import-xls-component
      ></v-col>
      <!-- Exportar Excel -->
      <v-col cols="auto" md="auto">
        <export-xls-component
          :eventsSelected="eventsSelected"
        ></export-xls-component>
      </v-col>
      <v-col cols="12" md="auto" class="ma-1"> </v-col>
      <v-col cols="12" md="2">
        <process-information-component
          :contractSelected="selectedValue"
          :dateStartSelected="dateStartSelected"
          :dateEndSelected="dateEndSelected"
          @events="handleEventsSelected"
        ></process-information-component
      ></v-col>
    </v-row>

    <!-- Filtros de Informe -->
    <v-row dense class="ma-4">
      <v-col cols="12" md="4">
        <!-- Campo de fecha "Inicio" -->
        <date-start-field-component
          @date-start-selected="handleDateStartSelected"
        ></date-start-field-component>
      </v-col>
      <v-col cols="12" md="4">
        <!-- Campo de fecha "Fin" -->
        <date-end-field-component
          @date-end-selected="handleDateEndSelected"
        ></date-end-field-component
      ></v-col>
    </v-row>

    <v-row dense justify="center" class="mx-4"
      ><v-col>
        <table-contracts-component
          :search="search"
          :contracts="contracts"
          @selected="handleSelected"
        ></table-contracts-component></v-col
    ></v-row>

    <!-- Divider -->
    <v-row dense class="ma-4">
      <v-divider></v-divider>
    </v-row>

    <!-- Titulo -->
    <v-row dense class="ma-4">
      <v-col>
        <h3>Operaciones Registradas</h3>
      </v-col>
    </v-row>

    <!-- Tabla -->
    <v-row dense class="ma-4">
      <v-col>
        <table-component :eventsSelected="eventsSelected"></table-component>
      </v-col>
    </v-row>
    <!-- BORRAR -->
    <!-- <v-row dense class="pa-4">
      <v-col> Pais: {{ this.countrySelected }} </v-col>
    </v-row>
    <v-row dense class="pa-4">
      <v-col>
        Contrato:
        {{ this.selectedValue }}
      </v-col>
    </v-row>
    <v-row dense class="pa-4">
      <v-col> Centro de Costo: {{ this.mallsSelected }} </v-col>
    </v-row>
    <v-row dense class="pa-4">
      <v-col> Fecha Inicio: {{ this.dateStartSelected }} </v-col>
    </v-row>
    <v-row dense class="pa-4">
      <v-col> Fecha Fin: {{ this.dateEndSelected }} </v-col>
    </v-row>
    <v-row>
      <v-col> {{ this.eventsSelected }}</v-col>
    </v-row> -->
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import DateStartFieldComponent from "@/components/Reports/DateStartFieldComponent.vue";
import DateEndFieldComponent from "@/components/Reports/DateEndFieldComponent.vue";
import ProcessInformationComponent from "@/components/Reports/ProcessInformationComponent.vue";
import ExportXlsComponent from "@/components/Reports/ExportXlsComponent.vue";
import ImportXlsComponent from "@/components/Reports/ImportXlsComponent.vue";
import TableComponent from "@/components/Reports/TableComponent.vue";
import TableContractsComponent from "@/components/Reports/TableContractsComponent.vue";

//import SelectedCountryComponent from "@/components/Reports/SelectedCountryComponent.vue";
// import SelectedMallComponent from "@/components/Reports/SelectedMallComponent.vue";
// import { db } from "../main";
// interface EventType {
//   id: string;
//   country: string;
//   mall: string;
//   registrant: string;
//   shop: string;
//   code: string;
//   businessname: string;
//   tradename: string;
//   start: string;
//   end: string;
//   color: string;
//   details: string;
//   operations: number;
//   amounts: number;
// }

interface User {
  contractsSelected: string[];
  profileSelected: string[];

  // otras propiedades de currentUser si las hay
}

interface Contracts {
  id: string;
  code: string;
  tradename: string;
  identifier: string;
  businessname: string;
  shop: string;
  mall: string;
  country: string;
}
import { db } from "../firebase";

export default Vue.extend({
  name: "ReportsView",

  props: {
    currentUser: {
      type: Object as () => User, // Define currentUser como una prop esperada en el componente
      required: false,
      default: () => ({
        contractsSelected: [],
        profileSelected: [],
      }),
    },
  },

  data: () => ({
    // users: [],
    // contracts: [],
    selectedValue: [] as Contracts[],
    search: "",
    fin: null,
    finMenu: false,
    //
    contracs: [],
    dateStartSelected: "",
    dateEndSelected: "",
    eventsSelected: [],
    //
    mallsSelected: [],
    countrySelected: "",
    itemsCountries: [],
    itemsMalls: [],
    //
    currentUserContracts: [] as string[],
    contracts: [] as Contracts[],
  }),

  components: {
    DateStartFieldComponent,
    DateEndFieldComponent,
    ProcessInformationComponent,
    ExportXlsComponent,
    ImportXlsComponent,
    TableComponent,
    TableContractsComponent,
    // SelectedCountryComponent,
    // SelectedMallComponent,
  },

  created() {
    // this.getCountries();
    // this.getMalls();
    // console.log("Current User:", this.currentUser);
    // Opcionalmente, puedes usar console.dir para una mejor visualización
    // console.dir(this.currentUser);
    // this.getContracts();
    if (this.currentUser && this.currentUser.contractsSelected) {
      this.currentUser.contractsSelected.forEach((contract: string) => {
        this.currentUserContracts.push(contract);
      });
    }
    this.getContracts();
  },

  watch: {
    currentUser: {
      handler() {
        if (this.currentUser && this.currentUser.contractsSelected) {
          this.currentUserContracts = [...this.currentUser.contractsSelected];
          this.getContracts(); // Actualiza los contratos cuando cambia currentUser
        }
      },
      deep: true,
    },
  },
  methods: {
    async getContracts() {
      try {
        if (this.currentUserContracts.length === 0) {
          this.contracts = [];
          return;
        }

        const batchedQueries = []; // Arreglo para almacenar las promesas de consultas por lotes
        const chunkSize = 30; // Tamaño máximo por lote

        for (let i = 0; i < this.currentUserContracts.length; i += chunkSize) {
          const chunk = this.currentUserContracts.slice(i, i + chunkSize); // Obtener el lote actual de hasta 30 elementos
          const query = db
            .collection("contratos")
            .where("code", "in", chunk)
            .get(); // Consultar Firebase
          batchedQueries.push(query); // Agregar la promesa al arreglo de consultas por lotes
        }

        // Ejecutar todas las consultas en paralelo y esperar a que todas se completen
        const snapshots = await Promise.all(batchedQueries);

        const contracts: Contracts[] = [];

        snapshots.forEach((snapshot) => {
          snapshot.forEach((doc) => {
            let perfilData = doc.data() as Contracts; // Asegurar que los datos son de tipo Contract
            perfilData.id = doc.id;
            contracts.push(perfilData);
          });
        });

        // // // const snapshot = await db
        // // //   .collection("contratos")
        // // //   .where("code", "in", this.currentUserContracts) // Filtrar por códigos de contrato del currentUser
        // // //   .get();

        // // // // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // // // const contracts: any = [];

        // // // snapshot.forEach((doc) => {
        // // //   let perfilData = doc.data();
        // // //   perfilData.id = doc.id;
        // // //   contracts.push(perfilData);
        // // // });
        // eslint-disable-next-line
        this.contracts = contracts.sort((a: any, b: any) => {
          return parseInt(a.code, 10) - parseInt(b.code, 10);
        });
      } catch (error) {
        console.log(error);
      }
    },
    // get Paises
    // async getCountries() {
    //   try {
    //     const snapshot = await db.collection("paises").get();
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     const itemsCountries: any = [];

    //     snapshot.forEach((doc) => {
    //       let paisData = doc.data();
    //       paisData.id = doc.id;
    //       itemsCountries.push(paisData);
    //     });
    //     this.itemsCountries = itemsCountries;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    // async getMalls() {
    //   try {
    //     const snapshot = await db.collection("malls").get();
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     const itemsMalls: any = [];

    //     snapshot.forEach((doc) => {
    //       let complejosData = doc.data();
    //       complejosData.id = doc.id;
    //       itemsMalls.push(complejosData);
    //     });
    //     this.itemsMalls = itemsMalls;
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    formatDate(date: string): string {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date: string): string {
      if (!date || date.trim() === "") return ""; // Manejar caso de fecha nula o vacía
      const parts = date.split("/");
      if (parts.length !== 3) return ""; // Manejar caso de formato de fecha incorrecto
      const [day, month, year] = parts;
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },

    handleDateStartSelected(date: string) {
      this.dateStartSelected = date;
    },

    handleDateEndSelected(date: string) {
      this.dateEndSelected = date;
    },

    handleEventsSelected(events: []) {
      this.eventsSelected = events;
    },

    handleSelected(selected: []) {
      this.selectedValue = selected;
    },

    // handleMallsSelected(selected: []) {
    //   this.mallsSelected = selected;
    // },
  },
});
</script>
