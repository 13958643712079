<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="selectedItem.length > 0"
          color="red"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-btn v-else disabled icon v-bind="attrs" v-on="on">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title> Eliminar </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <div class="text--primary">
            <br />
            ¿Estás seguro que deseas eliminarlo?
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog = false">Cancelar</v-btn>
          <v-btn class="white--text" color="#FD5C01" @click="deleteSelected"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Centro de Costo eliminado con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";

import { db } from "../../firebase";

@Component
export default class DeleteComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedItem!: unknown[];
  dialog = false;
  snackbar = false;
  snackbarTimeout = 3000;
  confirmDelete = false;

  async deleteItem(itemId: string) {
    try {
      await db.collection("malls").doc(itemId).delete();
      this.snackbar = true;
      // Emitir el evento de confirmación de eliminación exitosa
      this.emitConfirmDelete(true);
    } catch (error) {
      console.log(error);
    }
  }

  // Método para eliminar usuarios seleccionados
  async deleteSelected() {
    // eslint-disable-next-line
    for (const item of this.selectedItem as any[]) {
      await this.deleteItem(item.id);
    }
    this.dialog = false;
  }

  // Emit que envía la confirmación de la eliminación
  @Emit("confirmDelete")
  emitConfirmDelete(confirmDelete: boolean) {
    return confirmDelete;
  }
}
</script>
