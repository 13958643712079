<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="eventsFilter"
      :single-select="singleSelect"
      item-key="id"
      show-select
      class="elevation-1"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
      :search="search"
    >
      <template slot="item.end" slot-scope="{ item }">
        {{ formatDate(item.end) }}
      </template>

      <template slot="item.amounts" slot-scope="{ item }">
        {{ formatAmounts(item.amounts) }}
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        color="#FD5C01"
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";

interface EventType {
  id: string;
  country: string;
  mall: string;
  registrant: string;
  shop: string;
  code: string;
  businessname: string;
  tradename: string;
  start: Date; //
  end: Date; //
  color: string;
  details: string; //
  operations: number; //
  amounts: number; //
}

@Component
export default class TableComponent extends Vue {
  @Prop({ type: Array, required: true }) eventsFilter!: EventType[];
  @Prop() readonly search!: string;

  // Page
  page = 1;
  pageCount = 0;
  itemsPerPage = 10;
  // Select
  singleSelect = true;
  selected = [];
  // Data
  headers = [
    {
      text: "Fecha Operación",
      align: "center",
      sortable: false,
      value: "end",
    },
    {
      text: "Cantidad Operaciones",
      align: "center",
      sortable: false,
      value: "operations",
    },
    {
      text: "Importe Ventas",
      align: "center",
      sortable: false,
      value: "amounts",
    },
    {
      text: "Observaciones",
      align: "center",
      sortable: false,
      value: "details",
    },
  ];

  @Watch("selected")
  onSelectedChange(
    newValue: unknown
    //, oldValue: unknown
  ) {
    this.emitSelected(newValue);
  }

  @Emit("selected")
  emitSelected(selected: unknown) {
    return selected;
  }

  // Método computado para formatear la fecha
  formatDate(dateString: Date): string {
    const [year, month, day] = dateString.toLocaleString().split("-");
    return `${day}/${month}/${year}`;
  }

  // Método computado para formatear el monto
  formatAmounts(amountsString: string) {
    // Verificar si amountsString es un número válido
    const amount = parseFloat(amountsString);

    if (isNaN(amount)) {
      return "Cantidad no válida";
    }

    // Formatear el número con separadores de miles y el símbolo de peso chileno
    return new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }).format(amount);
  }
}
</script>
