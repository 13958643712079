<template>
  <v-app>
    <!-- Overlay -->
    <v-overlay v-model="overlay" class="align-center justify-center">
      <v-card color="white" class="full-screen-card">
        <v-progress-linear
          :active="overlay"
          :indeterminate="overlay"
          color="#FD5C01"
          absolute
          top
          height="8"
        >
        </v-progress-linear>

        <v-img
          class="mx-auto"
          height="300"
          max-width="500"
          :src="require('/public/img/logoFloridaGo.png')"
        >
        </v-img>
      </v-card>
    </v-overlay>

    <!-- AppBar -->
    <v-app-bar
      v-if="loggedIn && !overlay"
      app
      clipped-right
      clipped-left
      flat
      height="72"
      color="#463e3b"
      elevation="3"
    >
      <v-app-bar-nav-icon
        color="white"
        @click="drawerLeft = !drawerLeft"
      ></v-app-bar-nav-icon>

      <v-app-bar-title class="white--text">Bienvenido</v-app-bar-title>
      <v-spacer></v-spacer>

      <!-- 1 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="hasPermission('2')"
            class="white--text"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <span>Perfil</span>
      </v-tooltip>

      <!-- 2 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="hasPermission('2')"
            class="white--text"
            icon
            :to="{ name: 'users' }"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account-group</v-icon>
          </v-btn>
        </template>
        <span>Usuarios</span>
      </v-tooltip>

      <!-- 3 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="hasPermission('2')"
            class="white--text"
            icon
            v-bind="attrs"
            v-on="on"
            @click="drawerRight = !drawerRight"
          >
            <v-icon>mdi mdi-cogs</v-icon>
          </v-btn>
        </template>
        <span>Configuración</span>
      </v-tooltip>

      <!-- 4 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="white--text"
            icon
            v-bind="attrs"
            v-on="on"
            @click="logout"
          >
            <v-icon>mdi mdi-exit-to-app</v-icon>
          </v-btn>
        </template>
        <span>Cerrar Sesión</span>
      </v-tooltip>
    </v-app-bar>

    <!-- Left Navigation Drawer -->
    <v-navigation-drawer
      v-model="drawerLeft"
      v-if="loggedIn && !overlay"
      app
      clipped
      color="#463e3b"
    >
      <!-- <v-list-item-icon class="d-block text-center mb-9">
        <v-btn icon color="white">
          <v-icon color="#FFFFFFCC">mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item-icon> -->

      <!-- <v-divider class="my-4"></v-divider> -->

      <v-list>
        <v-list-item
          v-for="item in itemsLeft"
          :key="item.title"
          link
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon color="#FFFFFFCC">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              ><span class="white--text">{{
                item.title
              }}</span></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Right Navigation Drawer -->
    <v-navigation-drawer
      v-if="loggedIn && !overlay"
      app
      clipped
      right
      v-model="drawerRight"
      width="200"
      temporary
    >
      <v-list>
        <v-list-item
          v-for="item in itemsRight"
          :key="item.title"
          link
          :to="item.to"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Main -->
    <v-main>
      <router-view :currentUser="currentUser"></router-view>
    </v-main>

    <!-- Footer -->
    <footer-component v-if="loggedIn && !overlay"></footer-component>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import FooterComponent from "./components/FooterComponent.vue";
import firebase from "firebase/compat/app";
import { db } from "./firebase";

export default {
  name: "App",
  components: {
    FooterComponent,
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },

  data: () => ({
    loggedIn: false, // Estado de inicio de sesión
    drawerLeft: false,
    drawerRight: false,
    // miniVariant: true,
    // currentUser: {
    //   changepassword: "",
    //   contractsSelected: [],
    //   email: "",
    //   name: "",
    //   names: "",
    //   password: "",
    //   phones: "",
    //   status: "",
    //   surnames: "",
    //   uid: "",
    //   profileSelected: "",
    // },

    itemsLeft: [
      {
        title: "Registración",
        icon: "mdi-calendar-check-outline",
        to: { name: "monthlysalesregistration" },
      },
      {
        title: "Informes",
        icon: "mdi-file-document-outline",
        to: { name: "reports" },
      },
    ],

    itemsRight: [
      {
        title: "Perfiles",
        icon: "mdi-account-cog-outline",
        to: { name: "profiles" },
      },
      {
        title: "Contratos",
        icon: "mdi-account-cog-outline",
        to: { name: "contracts" },
      },
      {
        title: "Centros de Costo",
        icon: "mdi-account-group-outline",
        to: { name: "malls" },
      },
      {
        title: "Paises",
        icon: "mdi-account-group-outline",
        to: { name: "countries" },
      },
    ],
    overlay: false,
  }),

  created() {
    this.overlay = true;

    // Verificar el estado de la autenticación al cargar el componente
    firebase.auth().onAuthStateChanged(async (user) => {
      this.loggedIn = !!user;

      if (user) {
        const userDoc = await db
          .collection("usuarios")
          .where("uid", "==", user.uid) // Filtrar por el campo 'uid' igual al uid del usuario actual
          .get();

        userDoc.forEach((doc) => {
          this.$store.commit("setCurrentUser", doc.data());
          console.log(doc.id, " => ", doc.data());
        });

        // userDoc.forEach((doc) => {
        //   console.log(doc.id, " => ", doc.data());
        //   console.log(doc.id, " => ", doc.data().profileSelected);

        //   this.currentUser.changepassword = doc.data().changepassword || "";
        //   this.currentUser.contractsSelected =
        //     doc.data().contractsSelected || [];
        //   this.currentUser.email = doc.data().email || "";
        //   this.currentUser.name = doc.data().name || "";
        //   this.currentUser.names = doc.data().names || "";
        //   this.currentUser.password = doc.data().password || "";
        //   this.currentUser.phones = doc.data().phones || "";
        //   this.currentUser.status = doc.data().status || "";
        //   this.currentUser.surnames = doc.data().surnames || "";
        //   this.currentUser.uid = doc.data().uid || "";
        //   this.currentUser.profileSelected = doc.data().profileSelected || [
        //     "1",
        //   ];
        // });
      }
    });
  },
  methods: {
    // toggleRightDrawer() {
    //   this.miniVariantRight = !this.miniVariantRight;
    // },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Redirigir al usuario a la página de inicio de sesión después de cerrar la sesión
          this.$router.push("/login");
          console.log("Sesión cerrada correctamente");
        })
        .catch((error) => {
          console.error("Error al cerrar sesión:", error);
        });
    },
    // hasPermission(permission) {
    //   return this.currentUser.profileSelected.includes(permission);
    // },
    hasPermission(permission) {
      return this.$store.getters.currentUser?.profileSelected?.includes(
        permission
      );
    },
  },

  watch: {
    overlay(val) {
      if (val) {
        setTimeout(() => {
          // Verificar si el overlay todavía está visible antes de ocultarlo
          if (this.overlay) {
            this.overlay = false;
          }
        }, 1500);
      }
    },
  },
};
</script>

<style>
html {
  overflow-y: scroll !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  display: none;
}

.v-application--is-ltr {
  direction: ltr;
}

.full-screen-card {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
