<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="800" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="white--text text-initial font-weight-bold"
          color="#FD5C01"
          block
        >
          Agregar
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title> Agregar </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form>
              <v-text-field
                type="text"
                label="* Código"
                v-model="code"
              ></v-text-field>
              <v-select
                :items="itemsAsset"
                type="text"
                label="* Activo"
                v-model="asset"
              ></v-select>
              <v-text-field
                type="text"
                label="* Nombre"
                v-model="name"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Nombre Formateado"
                v-model="nameFormat"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Nivel"
                v-model="level"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Comuna"
                v-model="commune"
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog = false">Cancelar</v-btn>
          <v-btn class="white--text" color="#FD5C01" @click="submit"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Centro de Costo agregado con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

import { db } from "../../firebase";

@Component({})
export default class AddComponent extends Vue {
  @Prop({ type: Array, required: true }) malls!: unknown[];

  dialog = false;
  snackbar = false;
  confirmAdd = false;
  snackbarTimeout = 3000;

  // Atributos
  asset = "";
  code = "";
  name = "";
  nameFormat = "";
  level = "";
  commune = "";
  //
  itemsAsset = ["S", "N"];
  // Metodos
  async addItem() {
    try {
      if (
        this.asset &&
        this.code &&
        this.name &&
        this.nameFormat &&
        this.level &&
        this.commune
      ) {
        await db.collection("malls").add({
          // Atributos a agregar a la bd
          asset: this.asset,
          code: this.code,
          name: this.name.toUpperCase(),
          nameFormat: this.nameFormat.toUpperCase(),
          level: this.level,
          commune: this.commune.toUpperCase(),
        });

        // Emitir el evento de confirmación de eliminación exitosa
        this.emitConfirmAdd(true);
        this.snackbar = true;
        this.dialog = false;

        // Lipia variables
        this.asset = "";
        this.code = "";
        this.name = "";
        this.nameFormat = "";
        this.level = "";
        this.commune = "";
      } else {
        console.log("Campos Obligatorios");
      }
    } catch (error) {
      console.log(error);
    }
  }

  submit() {
    this.addItem(); // Llama al método addItem para enviar el formulario
  }

  // Emit que envía la confirmación de la agregación
  @Emit("confirmAdd")
  emitConfirmAdd(confirmAdd: boolean) {
    return confirmAdd;
  }
}
</script>
