<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="selectedItem.length > 0"
          color="success"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-else disabled icon v-bind="attrs" v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title> Editar </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form>
              <v-text-field
                type="text"
                label="* Código"
                v-model="code"
                disabled
              ></v-text-field>
              <v-select
                :items="itemsAsset"
                type="text"
                label="* Activo"
                v-model="asset"
              ></v-select>
              <v-text-field
                type="text"
                label="* Nombre"
                v-model="name"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Nombre Formateado"
                v-model="nameFormat"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Nivel"
                v-model="level"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Comuna"
                v-model="commune"
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog = false">Cancelar</v-btn>
          <v-btn class="white--text" color="#FD5C01" @click="submit"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      País editado con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";

import { db } from "../../firebase";

@Component
export default class EditComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedItem!: unknown[];

  // Atributos
  id = "";
  asset = "";
  code = "";
  name = "";
  nameFormat = "";
  level = "";
  commune = "";
  //
  itemsAsset = ["S", "N"];
  // Variables
  dialog = false;
  snackbar = false;
  snackbarTimeout = 3000;

  async editItem(itemId: string) {
    try {
      if (
        this.asset &&
        this.code &&
        this.name &&
        this.nameFormat &&
        this.level &&
        this.commune
      ) {
        await db.collection("malls").doc(itemId).set({
          // Atributos a agregar a la bd
          asset: this.asset,
          code: this.code,
          name: this.name.toUpperCase(),
          nameFormat: this.nameFormat.toUpperCase(),
          level: this.level,
          commune: this.commune.toUpperCase(),
        });

        // Emitir el evento de confirmación de eliminación exitosa
        this.emitConfirmEdit(true);

        this.snackbar = true;
        this.dialog = false;
      } else {
        console.log("Campos Obligatorios");
      }
    } catch (error) {
      console.log(error);
    }
  }

  submit() {
    this.editItem(this.id); // Llama al método addUser para enviar el formulario
  }

  // Emit que envía la confirmación de la agregación
  @Emit("confirmEdit")
  emitConfirmEdit(confirmEdit: boolean) {
    return confirmEdit;
  }

  @Watch("selectedItem")
  onSelectedChange() {
    // eslint-disable-next-line
    for (const item of this.selectedItem as any[]) {
      this.id = item.id;
      this.asset = item;
      this.code = item.code;
      this.name = item.name;
      this.nameFormat = item.nameFormat;
      this.level = item.level;
      this.commune = item.commune;
    }
  }
}
</script>
