<template>
  <div class="text-center">
    <v-btn v-if="selectedItem.length > 0" icon v-on:click.prevent="exportPdf()">
      <v-icon>mdi-file-pdf-box</v-icon>
    </v-btn>
    <v-btn v-else disabled icon v-on:click.prevent="exportPdf()">
      <v-icon>mdi-file-pdf-box</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface Contratos {
  code: string;
  tradename: string;
  businessname: string;
  identifier: string;
  shop: string;
  mall: string;
  country: string;
}

@Component
export default class DownloadComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedItem!: Contratos[];

  // Atributos
  code = "";
  tradename = "";
  businessname = "";
  identifier = "";
  shop = "";
  mall = "";
  country = "";

  //Methods
  exportPdf() {
    let docDefinition = {
      content: [
        { text: "Contratos", style: "header" },
        {
          style: "tableExample",
          table: {
            widths: [50, "*", "*", "*", "*", "*", 50],
            body: [
              [
                { text: "Código", style: "tableHeader" },
                { text: "Nombre Comercial", style: "tableHeader" },
                { text: "Razón Social", style: "tableHeader" },
                { text: "Identificación", style: "tableHeader" },
                { text: "Centro Comercial", style: "tableHeader" },
                { text: "Local", style: "tableHeader" },
                { text: "País", style: "tableHeader" },
              ],

              [
                this.code,
                this.businessname,
                this.tradename,
                this.identifier,
                this.mall,
                this.shop,
                this.country,
              ],
            ],
          },
          layout: "lightHorizontalLines",
        },
      ],
      pageOrientation: "landscape",
      pageMargins: [40, 40, 40, 40],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
      },
      defaultStyle: {
        fontSize: 8,
        //alignment: "justify",
      },
    };
    const pdf = pdfMake.createPdf(docDefinition);
    pdf.download("Dato_Contrato");
  }

  created() {
    this.updateInfo();
  }

  get hasSelectedItem() {
    return this.selectedItem.length > 0;
  }

  updateInfo() {
    if (this.hasSelectedItem) {
      const item = this.selectedItem[0];
      this.code = item.code;
      this.tradename = item.tradename;
      this.businessname = item.businessname;
      this.identifier = item.identifier;
      this.shop = item.shop;
      this.mall = item.mall;
      this.country = item.country;
    } else {
      // Si no hay usuario seleccionado, reiniciar valores
      this.resetInfo();
    }
  }

  resetInfo() {
    this.code = "";
    this.tradename = "";
    this.businessname = "";
    this.identifier = "";
    this.shop = "";
    this.mall = "";
    this.country = "";
  }

  @Watch("selectedItem")
  onSelectedUserChange(newVal: Contratos[], oldVal: Contratos[]) {
    if (newVal !== oldVal) {
      this.updateInfo();
    }
  }
}
</script>
