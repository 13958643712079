<template>
  <div class="text-center">
    <v-btn v-if="selectedUser.length > 0" icon v-on:click.prevent="exportPdf()">
      <v-icon>mdi-file-pdf-box</v-icon>
    </v-btn>
    <v-btn v-else icon v-on:click.prevent="getUsers()">
      <v-icon>mdi-file-pdf-box</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { db } from "../../firebase";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface User {
  code: string;
  name: string;
  email: string;
  status: string;
  names: string;
  surnames: string;
  phones: string;
  changepassword: string;
}

@Component
export default class DownloadComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedUser!: User[];

  // Data
  users: User[] = [];
  // Atributos
  code = "";
  name = "";
  email = "";
  status = "";
  names = "";
  surnames = "";
  phones = "";
  changepassword = "";

  //Methods
  exportPdf() {
    let docDefinition = {
      content: [
        { text: "Usuarios", style: "header" },
        {
          style: "tableExample",
          table: {
            widths: [50, "*", "*", "*", "*", "*", 50, 50],
            body: [
              [
                { text: "Interno", style: "tableHeader" },
                { text: "Usuario", style: "tableHeader" },
                { text: "Correo", style: "tableHeader" },
                { text: "Nombres", style: "tableHeader" },
                { text: "Apellidos", style: "tableHeader" },
                { text: "Teléfono", style: "tableHeader" },
                { text: "Estado", style: "tableHeader" },
                { text: "C. Clave", style: "tableHeader" },
              ],

              [
                "*",
                this.name,
                this.email,
                this.names,
                this.surnames,
                this.phones,
                this.status,
                this.changepassword,
              ],
            ],
          },
          layout: "lightHorizontalLines",
        },
      ],
      pageOrientation: "landscape",
      pageMargins: [40, 40, 40, 40],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
      },
      defaultStyle: {
        fontSize: 8,
        //alignment: "justify",
      },
    };
    const pdf = pdfMake.createPdf(docDefinition);
    pdf.download("Dato_Usuario");
  }

  // get Usuarios
  async getUsers() {
    try {
      const snapshot = await db.collection("usuarios").get();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const users: any = [];

      snapshot.forEach((doc) => {
        let usuarioData = doc.data();
        usuarioData.id = doc.id;
        users.push(usuarioData);
      });
      // Ordenar los usuarios manualmente por el campo `code` convertido a número
      // eslint-disable-next-line
      this.users = users.sort((a: any, b: any) => {
        return parseInt(a.code, 10) - parseInt(b.code, 10);
      });

      this.exportAllPdf();
    } catch (error) {
      console.log(error);
    }
  }

  exportAllPdf() {
    const tableBody = [
      [
        { text: "Interno", style: "tableHeader" },
        { text: "Usuario", style: "tableHeader" },
        { text: "Correo", style: "tableHeader" },
        { text: "Nombres", style: "tableHeader" },
        { text: "Apellidos", style: "tableHeader" },
        { text: "Teléfono", style: "tableHeader" },
        { text: "Estado", style: "tableHeader" },
        { text: "C. Clave", style: "tableHeader" },
      ],
    ];

    this.users.forEach((user) => {
      tableBody.push([
        { text: user.code, style: "tableCell" },
        { text: user.name.toUpperCase(), style: "tableCell" },
        { text: user.email.toUpperCase(), style: "tableCell" },
        { text: user.names.toUpperCase(), style: "tableCell" },
        { text: user.surnames.toUpperCase(), style: "tableCell" },
        { text: user.phones, style: "tableCell" },
        { text: user.status, style: "tableCell" },
        { text: user.changepassword, style: "tableCell" },
      ]);
    });

    let docDefinition = {
      content: [
        { text: "Usuarios", style: "header" },
        {
          style: "tableExample",
          table: {
            widths: [40, 150, 150, 100, 50, 60, 50, 50],
            body: tableBody,
          },
          layout: "lightHorizontalLines",
        },
      ],
      pageOrientation: "landscape",
      pageMargins: [40, 40, 40, 40],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
        tableCell: {
          fontSize: 8,
          color: "black",
        },
      },
      defaultStyle: {
        fontSize: 8,
        //alignment: "justify",
      },
    };

    const pdf = pdfMake.createPdf(docDefinition);
    pdf.download("Datos_Usuarios");
  }
  created() {
    this.updateUserInfo();
  }

  get hasSelectedUser() {
    return this.selectedUser.length > 0;
  }

  updateUserInfo() {
    if (this.hasSelectedUser) {
      const user = this.selectedUser[0];
      this.code = user.code;
      this.name = user.name;
      this.email = user.email;
      this.status = user.status;
      this.names = user.names;
      this.surnames = user.surnames;
      this.phones = user.phones;
      this.changepassword = user.changepassword;
    } else {
      // Si no hay usuario seleccionado, reiniciar valores
      this.resetUserInfo();
    }
  }

  resetUserInfo() {
    this.code = "";
    this.name = "";
    this.email = "";
    this.status = "";
    this.names = "";
    this.surnames = "";
    this.phones = "";
    this.changepassword = "";
  }

  @Watch("selectedUser")
  onSelectedUserChange(newVal: User[], oldVal: User[]) {
    if (newVal !== oldVal) {
      this.updateUserInfo();
    }
  }
}
</script>
