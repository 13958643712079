<template>
  <div class="text-center">
    <v-btn
      v-if="itemSelected.length > 0"
      icon
      v-on:click.prevent="exportExcel()"
    >
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <v-btn v-else disabled icon v-on:click.prevent="exportExcel()">
      <v-icon>mdi-download</v-icon>
    </v-btn>
  </div>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  props: {
    itemSelected: {
      type: Array,
      required: true,
    },
  },

  methods: {
    exportExcel() {
      const data = this.ordenesTransformados;
      const ws = XLSX.utils.json_to_sheet(data);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Centros de Costo");
      XLSX.writeFile(wb, "Descargar_Centros_de_Costo.xlsx");
    },
  },

  computed: {
    ordenesTransformados() {
      return this.itemSelected.map((item) => {
        return {
          ["Código"]: item.code,
          ["Activo"]: item.asset,
          ["Nombre"]: item.name,
          ["Nombre Formateado"]: item.nameFormat,
          ["Nivel"]: item.level,
          ["Comuna"]: item.commune,
        };
      });
    },
  },
};
</script>
