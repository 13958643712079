<template>
  <div class="text-center mx-4">
    <v-row
      ><v-col><h2 class="text-left my-3">Selección</h2></v-col><v-col></v-col
    ></v-row>
    <v-card color="#E8E8E8" rounded="0" outlined class="mx-auto">
      <v-row class="ma-4"
        ><v-col class="text-left" md="4">Contrato</v-col
        ><v-col class="text-right" md="8">{{ code }}</v-col></v-row
      >
    </v-card>
    <v-card rounded="0" outlined class="mx-auto">
      <v-row class="ma-4"
        ><v-col class="text-left" md="4">Nombre Comercial</v-col
        ><v-col class="text-right" md="8">{{ this.tradename }}</v-col></v-row
      >
    </v-card>
    <v-card color="#E8E8E8" rounded="0" outlined class="mx-auto">
      <v-row class="ma-4"
        ><v-col class="text-left" md="4">Centro Comercial</v-col
        ><v-col class="text-right" md="8">{{ this.mall }}</v-col></v-row
      >
    </v-card>
    <v-card rounded="0" outlined class="mx-auto">
      <v-row class="ma-4"
        ><v-col class="text-left" md="4">Local</v-col
        ><v-col class="text-right" md="8">{{ this.shop }}</v-col></v-row
      >
    </v-card>
    <v-card color="#E8E8E8" rounded="0" outlined class="mx-auto">
      <v-row class="ma-4"
        ><v-col class="text-left" md="4">Razón Social</v-col
        ><v-col class="text-right" md="8">{{ this.businessname }}</v-col></v-row
      >
    </v-card>
    <v-card rounded="0" outlined class="mx-auto">
      <v-row class="ma-4"
        ><v-col class="text-left" md="4">Identificación</v-col
        ><v-col class="text-right" md="8">{{ this.identifier }}</v-col></v-row
      >
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

interface Contracts {
  code: string;
  tradename: string;
  identifier: string;
  businessname: string;
  shop: string;
  mall: string;
  country: string;
}

@Component
export default class InfoComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedItem!: Contracts[];

  // Atributos
  code = "";
  tradename = "";
  identifier = "";
  businessname = "";
  shop = "";
  mall = "";
  country = "";

  get hasSelected() {
    return this.selectedItem.length > 0;
  }

  @Watch("selectedItem")
  onSelectedUserChange(newVal: Contracts[], oldVal: Contracts[]) {
    if (newVal !== oldVal) {
      this.updateInfo();
    }
  }

  created() {
    this.updateInfo();
  }

  updateInfo() {
    if (this.hasSelected) {
      const user = this.selectedItem[0];
      this.code = user.code;
      this.tradename = user.tradename;
      this.identifier = user.identifier;
      this.businessname = user.businessname;
      this.shop = user.shop;
      this.mall = user.mall;
      this.country = user.country;
    } else {
      // Si no hay usuario seleccionado, reiniciar valores
      this.resetInfo();
    }
  }

  resetInfo() {
    this.code = "";
    this.tradename = "";
    this.identifier = "";
    this.businessname = "";
    this.shop = "";
    this.mall = "";
    this.country = "";
  }
}
</script>
