<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="white--text text-initial font-weight-bold"
          color="#FD5C01"
          block
        >
          Agregar
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title> Agregar </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form>
              <v-text-field
                type="text"
                label="* Código"
                v-model="code"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Descripción"
                v-model="description"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Abreviatura"
                v-model="abbreviation"
              ></v-text-field>
              <v-select
                type="text"
                label="* Hábilitado"
                v-model="status"
                :items="itemsStatus"
              ></v-select>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog = false">Cancelar</v-btn>
          <v-btn class="white--text" color="#FD5C01" @click="submit"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Perfil agregado con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit } from "vue-property-decorator";

import { db } from "../../firebase";

@Component
export default class AddComponent extends Vue {
  dialog = false;
  snackbar = false;
  confirmAdd = false;
  snackbarTimeout = 3000;

  // Atributos
  code = "";
  description = "";
  abbreviation = "";
  status = "";
  itemsStatus = ["SI", "NO"];

  // Metodos
  async addProfile() {
    try {
      if (this.code && this.description && this.abbreviation && this.status) {
        // Genera un Nuevo Usuario en Firebase Firestore
        await db.collection("perfiles").add({
          code: this.code,
          description: this.description,
          abbreviation: this.abbreviation,
          status: this.status,
        });

        // Emitir el evento de confirmación de eliminación exitosa
        this.emitConfirmAdd(true);

        this.snackbar = true;
        this.dialog = false;

        // Lipia variables
        this.code = "";
        this.description = "";
        this.abbreviation = "";
        this.status = "";
      } else {
        console.log("Campos Obligatorios");
      }
    } catch (error) {
      console.log(error);
    }
  }

  submit() {
    this.addProfile(); // Llama al método addProfile para enviar el formulario
  }

  // Emit que envía la confirmación de la agregación
  @Emit("confirmAdd")
  emitConfirmAdd(confirmAdd: boolean) {
    return confirmAdd;
  }
}
</script>
