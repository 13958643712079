<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
    elevation="15"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        label="* Fecha Inicio"
        hint="DD/MM/YYYY"
        outlined
        readonly
        dense
        persistent-hint
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        @blur="date = parseDate(dateFormatted)"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
    };
  },

  computed: {
    dateFormatted: {
      get() {
        return this.formatDate(this.date);
      },
      set(value) {
        this.date = this.parseDate(value);
      },
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      this.$emit("date-start-selected", this.date);
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const parts = date.split("/");
      if (parts.length !== 3) {
        // El formato de fecha es incorrecto
        return null;
      }

      const [day, month, year] = parts;
      const parsedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      if (isNaN(new Date(parsedDate).getTime())) {
        // La fecha ingresada no es válida
        return null;
      }

      return parsedDate;
    },
  },
};
</script>
