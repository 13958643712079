<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="profiles"
      :single-select="singleSelect"
      item-key="id"
      show-select
      class="elevation-0"
    >
    </v-data-table>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";

interface Profile {
  id: string;
  code: string;
  abbreviation: string;
  description: string;
  status: string;
}

@Component
export default class TableComponent extends Vue {
  @Prop({ type: Array, required: true }) profiles!: Profile[];
  @Prop({ type: Array, required: true }) profileSelected!: string[];

  // Select
  singleSelect = true;
  selected: Profile[] = [];

  // Data
  headers = [
    { text: "Código", align: "center", sortable: false, value: "code" },
    {
      text: "Abreviación",
      align: "center",
      sortable: false,
      value: "abbreviation",
    },
    {
      text: "Descripción",
      align: "center",
      sortable: false,
      value: "description",
    },
    {
      text: "Estado",
      align: "center",
      sortable: false,
      value: "status",
    },
  ];

  created() {
    // Inicializar 'selected' con los profiles seleccionados
    this.selected = this.profiles.filter((profile) =>
      this.profileSelected.includes(profile.code)
    );
  }

  @Watch("selected")
  onSelectedChange(
    // eslint-disable-next-line
    newValue: any
    //, oldValue: unknown
  ) {
    // eslint-disable-next-line
    const selectedIds = newValue.map((item: any) => (item as any).code);
    this.emitSelected(selectedIds);
    console.log(selectedIds);
  }

  @Emit("selected")
  emitSelected(selected: unknown) {
    return selected;
  }
}
</script>
