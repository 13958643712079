<template>
  <v-container>
    <v-row class="d-flex justify-center align-center" style="height: 80vh">
      <!-- <v-col cols="12">
        <v-img
          :src="require('../../public/img/logoFidelmira.jpg')"
          class="my-3"
          contain
          height="50"
        />
      </v-col> -->

      <!-- <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Bienvenido</h1>

        <p class="subheading font-weight-regular">
          Sistema Web creado para que los locatarios puedan
          <br />registrar sus ventas.
        </p>
      </v-col> -->

      <v-col cols="12" class="d-flex justify center">
        <v-img
          :src="require('../../public/img/logoFloridaGo.png')"
          contain
          height="250"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "HelloWorld",

  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer",
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
});
</script>

<style scoped>
.v-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
