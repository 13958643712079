<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="1400" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltipOn }">
            <v-btn
              v-if="selectedUser.length > 0"
              color="success"
              icon
              v-bind="attrs"
              v-on="{ ...on, ...tooltipOn }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-else
              disabled
              icon
              v-bind="attrs"
              v-on="{ ...on, ...tooltipOn }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title> Editar </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form>
              <v-text-field
                type="text"
                label="* UID"
                v-model="uid"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Código"
                v-model="code"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Usuario"
                v-model="name"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Correo"
                v-model="email"
              ></v-text-field>
              <v-text-field
                type="password"
                label="* Clave"
                v-model="password"
              ></v-text-field>

              <v-text-field
                type="text"
                label="* Estado"
                v-model="status"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Nombres"
                v-model="names"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Apellidos"
                v-model="surnames"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Teléfonos"
                v-model="phones"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Cambiar Clave"
                v-model="changepassword"
              ></v-text-field>
            </v-form>

            <v-row class="my-4"><v-divider></v-divider></v-row>
            <v-row>
              <v-col>
                <h3>Perfil</h3>
              </v-col>
            </v-row>
            <v-row class="my-4"
              ><v-col
                ><table-component-edit-profiles
                  :profiles="profiles"
                  :profileSelected="profileSelected"
                  @selected="handleSelectedProfile"
                ></table-component-edit-profiles></v-col
            ></v-row>
            <v-row class="my-4"><v-divider></v-divider></v-row>
            <v-row>
              <v-col>
                <h3>Contratos</h3>
              </v-col>
            </v-row>
            <v-row class="my-4">
              {{ selectedUser }}
              {{ contractsSelected }}
              <v-col
                ><table-component-edit-contracts
                  :contracts="contracts"
                  :contractsSelected="contractsSelected"
                  @selected="handleSelected"
                ></table-component-edit-contracts></v-col
            ></v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog = false">Cancelar</v-btn>
          <v-btn class="white--text" color="#FD5C01" @click="submit"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Usuario editado con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";
import TableComponentEditContracts from "../Users/TableComponentEditContacts.vue";
import TableComponentEditProfiles from "../Users/TableComponentEditProfiles.vue";

import { db } from "../../firebase";
//import firebase from "firebase/compat/app";

@Component({
  components: {
    TableComponentEditContracts,
    TableComponentEditProfiles,
  },
})
export default class EditComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedUser!: unknown[];
  @Prop({ type: Array, required: true }) contracts!: unknown[];
  @Prop({ type: Array, required: true }) profiles!: unknown[];

  // Atributos
  code = "";
  id = "";
  name = "";
  email = "";
  password = "";
  status = "";
  names = "";
  surnames = "";
  phones = "";
  changepassword = "";
  uid = "";
  // eslint-disable-next-line
  contractsSelected: any[] = [];
  // eslint-disable-next-line
  profileSelected: any[] = [];

  // Variables
  dialog = false;
  snackbar = false;
  snackbarTimeout = 3000;
  itemsChangepassword = ["SI", "NO"];
  itemsStatus = ["HABILITADO", "BLOQUEADO", "DESHABILITADO"];

  async editUser(userId: string) {
    try {
      await db.collection("usuarios").doc(userId).set({
        code: this.code,
        name: this.name,
        email: this.email,
        password: this.password,
        status: this.status,
        names: this.names,
        surnames: this.surnames,
        phones: this.phones,
        changepassword: this.changepassword,
        uid: this.uid,
        contractsSelected: this.contractsSelected,
        profileSelected: this.profileSelected,
      });

      // Edita el Usuario en Firebase Auth

      // Emitir el evento de confirmación de eliminación exitosa
      this.emitConfirmEdit(true);

      this.snackbar = true;
      this.dialog = false;
    } catch (error) {
      console.log(error);
    }
  }

  submit() {
    this.editUser(this.id); // Llama al método addUser para enviar el formulario
  }

  // eslint-disable-next-line
  handleSelected(selected: any[]) {
    this.contractsSelected = selected || [];
  }

  // eslint-disable-next-line
  handleSelectedProfile(selected: any[]) {
    this.profileSelected = selected || [];
  }
  // Emit que envía la confirmación de la agregación
  @Emit("confirmEdit")
  emitConfirmEdit(confirmEdit: boolean) {
    return confirmEdit;
  }

  // @Watch("selectedUser")
  // onSelectedChange() {
  //   // eslint-disable-next-line
  //   for (const user of this.selectedUser as any[]) {
  //     this.id = user.id;
  //     this.code = user.code;
  //     this.name = user.name;
  //     this.email = user.email;
  //     this.password = user.password;
  //     this.status = user.status;
  //     this.names = user.names;
  //     this.surnames = user.surnames;
  //     this.phones = user.phones;
  //     this.changepassword = user.changepassword;
  //     this.uid = user.uid;
  //     // Asegurarse de que contractsSelected y profileSelected sean arrays
  //     this.contractsSelected = Array.isArray(user.contractsSelected)
  //       ? [...user.contractsSelected]
  //       : [];
  //     this.profileSelected = Array.isArray(user.profileSelected)
  //       ? [...user.profileSelected]
  //       : [];
  //   }
  // }

  @Watch("selectedUser")
  onSelectedChange() {
    // Asegurarse de que selectedUser no esté vacío y sea un array
    if (this.selectedUser && this.selectedUser.length > 0) {
      const user = this.selectedUser[0] as {
        id: string;
        code: string;
        name: string;
        email: string;
        password: string;
        status: string;
        names: string;
        surnames: string;
        phones: string;
        changepassword: string;
        uid: string;
        contractsSelected?: string[]; // contractsSelected puede ser opcional
        profileSelected?: string[]; // profileSelected puede ser opcional
      };

      // Verificar y asignar los valores del usuario seleccionado
      this.id = user.id || "";
      this.code = user.code || "";
      this.name = user.name || "";
      this.email = user.email || "";
      this.password = user.password || "";
      this.status = user.status || "";
      this.names = user.names || "";
      this.surnames = user.surnames || "";
      this.phones = user.phones || "";
      this.changepassword = user.changepassword || "";
      this.uid = user.uid || "";

      // Asignar contractsSelected asegurándose de manejar el caso null o undefined
      if (user.contractsSelected) {
        this.contractsSelected = [...user.contractsSelected];
      } else {
        this.contractsSelected = [];
      }

      // Asignar profileSelected asegurándose de manejar el caso null o undefined
      if (user.profileSelected) {
        this.profileSelected = [...user.profileSelected];
      } else {
        this.profileSelected = [];
      }
    }
  }
}
</script>
