<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="1200" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-import</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title>Importar</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-file-input
              accept=".xlsx"
              label="Excel"
              v-model="archivo"
              @change="handleFileUpload($event)"
            ></v-file-input>
          </v-container>
        </v-card-text>

        <v-card-text v-if="items.length > 0">
          <v-container>
            <v-data-table :headers="header" :items="items"></v-data-table>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="#FD5C01"
            @click="uploadItemsToFirestore"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Centros de Costos Importados con Éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit } from "vue-property-decorator";
import * as XLSX from "xlsx";

import { db } from "../../firebase";
//import firebase from "firebase/compat/app";

@Component
export default class ImportXlsComponent extends Vue {
  archivo: Blob | null = null;
  header = [
    { text: "Código", value: "code" },
    { text: "Descripción", value: "name" },
    { text: "Nivel", value: "level" },
    { text: "Activo", value: "asset" },
    { text: "Desc. Formato", value: "nameFormat" },
    { text: "Comuna", value: "commune" },
  ];

  // eslint-disable-next-line
  items: any[] = [];

  confirmUpload = false;
  dialog = false;
  snackbar = false;
  snackbarTimeout = 3000;
  uid = "";

  // eslint-disable-next-line
  handleFileUpload(file: any) {
    if (file) {
      this.readFile(file);
    }
  }

  readFile(file: Blob) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target?.result;
      if (binaryString) {
        this.parseExcel(binaryString);
      }
    };
    reader.readAsBinaryString(file);
  }

  // eslint-disable-next-line
  parseExcel(binaryString: any) {
    const workbook = XLSX.read(binaryString, { type: "binary" });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 2 });

    // eslint-disable-next-line
    this.items = data.map((row: any) => ({
      code: row[0],
      name: row[1],
      level: row[2],
      asset: row[3],
      nameFormat: row[4],
      commune: row[5],
    }));
  }

  async uploadItemsToFirestore() {
    if (this.items.length > 0) {
      await Promise.all(
        this.items.map(async (item) => {
          db.collection("malls").add({
            code: item.code,
            name: item.name,
            level: item.level,
            asset: item.asset,
            nameFormat: item.nameFormat,
            commune: item.commune,
          });
        })
      );

      this.emitConfirmUpload(true);
      this.snackbar = true;
      this.dialog = false;
      this.items = [];
    } else {
      console.log("No hay elementos para subir");
    }
  }

  @Emit("confirmUpload")
  emitConfirmUpload(confirmUpload: boolean) {
    return confirmUpload;
  }
}
</script>
