import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "./firebase";

//import VueTextareaAutosize from "vue-textarea-autosize";

// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import "firebase/compat/firestore";

Vue.config.productionTip = false;

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDo-YdLs7QHpn4vP286AKNfYNz9VDfJAN4",
//   authDomain: "sistema-ventas-b53ba.firebaseapp.com",
//   projectId: "sistema-ventas-b53ba",
//   storageBucket: "sistema-ventas-b53ba.appspot.com",
//   messagingSenderId: "506886979421",
//   appId: "1:506886979421:web:0db97518774536a1bd72b4",
// };

// Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig);

// export const db = app.firestore();

// new Vue({
//   router,
//   store,
//   vuetify,
//   render: (h) => h(App),
// }).$mount("#app");

firebase.auth().onAuthStateChanged(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
