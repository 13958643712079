<template>
  <v-container fluid>
    <v-row dense class="ma-4">
      <v-col>
        <h1>Usuarios</h1>
      </v-col>
    </v-row>

    <v-row dense justify="center" class="mx-4">
      <v-col>
        <v-text-field
          outlined
          label="Busqueda"
          v-model="search"
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6"></v-col>
      <v-col cols="auto" md="auto">
        <import-component :selectedUser="selectedValue"></import-component>
      </v-col>
      <v-col cols="auto" md="auto">
        <visualize-component
          :selectedUser="selectedValue"
        ></visualize-component>
      </v-col>

      <v-col cols="auto" md="auto">
        <download-component :selectedUser="selectedValue"></download-component>
      </v-col>

      <v-col cols="auto" md="auto">
        <edit-component
          :contracts="contracts"
          :profiles="profiles"
          :selectedUser="selectedValue"
          @confirmEdit="confirmEdit"
        ></edit-component>
      </v-col>

      <v-col cols="auto" md="auto">
        <delete-component
          :selectedUser="selectedValue"
          @confirmDelete="confirmDelete"
        ></delete-component>
      </v-col>
      <v-col cols="12" md="auto" class="ma-1"> </v-col>

      <v-col cols="12" md="2">
        <add-component
          :contracts="contracts"
          :profiles="profiles"
          @confirmAdd="confirmAdd"
        ></add-component>
      </v-col>
    </v-row>

    <v-row dense justify="center" class="mx-4">
      <v-divider></v-divider>
    </v-row>

    <v-row dense justify="center" class="pa-4">
      <v-col>
        <table-component
          :search="search"
          :users="users"
          @selected="handleSelected"
        ></table-component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import DownloadComponent from "../components/Users/DownloadComponent.vue";
import VisualizeComponent from "../components/Users/VisualizeComponent.vue";
import EditComponent from "../components/Users/EditComponent.vue";
import DeleteComponent from "../components/Users/DeleteComponent.vue";
import AddComponent from "../components/Users/AddComponent.vue";
import TableComponent from "../components/Users/TableComponent.vue";
import ImportComponent from "../components/Users/ImportComponent.vue";

import { db } from "../firebase";

export default Vue.extend({
  name: "UsersView",

  data: () => ({
    users: [],
    contracts: [],
    profiles: [],
    selectedValue: [],
    confirmed: false,
    search: "",
  }),

  components: {
    DownloadComponent,
    VisualizeComponent,
    EditComponent,
    DeleteComponent,
    AddComponent,
    TableComponent,
    ImportComponent,
  },

  created() {
    this.getUsers();
    this.getContracts();
    this.getProfiles();
  },
  methods: {
    // get Usuarios
    async getUsers() {
      try {
        const snapshot = await db.collection("usuarios").get();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const users: any = [];

        snapshot.forEach((doc) => {
          let usuarioData = doc.data();
          usuarioData.id = doc.id;
          users.push(usuarioData);
        });
        // eslint-disable-next-line
        this.users = users.sort((a: any, b: any) => {
          return parseInt(a.code, 10) - parseInt(b.code, 10);
        });
      } catch (error) {
        console.log(error);
      }
    },

    // get Contratos
    async getContracts() {
      try {
        const snapshot = await db.collection("contratos").get();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const contracts: any = [];

        snapshot.forEach((doc) => {
          let perfilData = doc.data();
          perfilData.id = doc.id;
          contracts.push(perfilData);
        });
        this.contracts = contracts;
      } catch (error) {
        console.log(error);
      }
    },

    // get Perfiles
    async getProfiles() {
      try {
        const snapshot = await db.collection("perfiles").get();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const profiles: any = [];

        snapshot.forEach((doc) => {
          let perfilData = doc.data();
          perfilData.id = doc.id;
          profiles.push(perfilData);
        });
        this.profiles = profiles;
      } catch (error) {
        console.log(error);
      }
    },

    handleSelected(selected: []) {
      this.selectedValue = selected;
    },

    confirmDelete() {
      this.selectedValue = [];
      this.getUsers();
    },

    confirmAdd() {
      this.getUsers();
    },

    confirmEdit() {
      this.getUsers();
    },
  },
});
</script>
