<template>
  <div class="text-center">
    <v-btn v-if="selectedItem.length > 0" icon v-on:click.prevent="exportPdf()">
      <v-icon>mdi-file-pdf-box</v-icon>
    </v-btn>
    <v-btn v-else disabled icon v-on:click.prevent="exportPdf()">
      <v-icon>mdi-file-pdf-box</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface Mall {
  asset: string;
  code: string;
  name: string;
  nameFormat: string;
  level: string;
  commune: string;
}

@Component
export default class DownloadComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedItem!: Mall[];

  // Data
  asset = "";
  code = "";
  name = "";
  nameFormat = "";
  level = "";
  commune = "";
  //Methods
  exportPdf() {
    let docDefinition = {
      content: [
        { text: "Centros de Costo", style: "header" },
        {
          style: "tableExample",
          table: {
            widths: [50, "*", "*", "*", "*", "*"],
            body: [
              [
                { text: "Código", style: "tableHeader" },
                { text: "Nombre", style: "tableHeader" },
                { text: "Nombre Formato", style: "tableHeader" },
                { text: "Nivel", style: "tableHeader" },
                { text: "Comuna", style: "tableHeader" },
                { text: "Activo", style: "tableHeader" },
              ],

              [
                this.code,
                this.name,
                this.nameFormat,
                this.level,
                this.commune,
                this.asset,
              ],
            ],
          },
          layout: "lightHorizontalLines",
        },
      ],
      pageOrientation: "landscape",
      pageMargins: [40, 40, 40, 40],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
      },
      defaultStyle: {
        fontSize: 8,
        //alignment: "justify",
      },
    };
    const pdf = pdfMake.createPdf(docDefinition);
    pdf.download("Dato_Centro_de_Costo");
  }

  created() {
    this.updateInfo();
  }

  get hasSelected() {
    return this.selectedItem.length > 0;
  }

  updateInfo() {
    if (this.hasSelected) {
      const user = this.selectedItem[0];
      this.asset = user.asset;
      this.code = user.code;
      this.name = user.name;
      this.nameFormat = user.nameFormat;
      this.level = user.level;
      this.commune = user.commune;
    } else {
      // Si no hay usuario seleccionado, reiniciar valores
      this.resetInfo();
    }
  }

  resetInfo() {
    this.asset = "";
    this.code = "";
    this.name = "";
    this.nameFormat = "";
    this.level = "";
    this.commune = "";
  }

  @Watch("selectedItem")
  onSelectedItemChange(newVal: Mall[], oldVal: Mall[]) {
    if (newVal !== oldVal) {
      this.updateInfo();
    }
  }
}
</script>
