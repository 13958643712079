<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="white--text text-initial font-weight-bold"
          color="#FD5C01"
          block
        >
          Agregar
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title> Agregar </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form>
              <v-text-field
                type="text"
                label="* Código"
                v-model="code"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Nombre Comercial"
                v-model="tradename"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Razón Social"
                v-model="businessname"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Identificación"
                v-model="identifier"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Local"
                v-model="shop"
              ></v-text-field>
              <v-select
                type="text"
                label="* Centro Comercial"
                v-model="mall"
                :items="malls"
                item-text="name"
                item-value="name"
              ></v-select>
              <v-select
                type="text"
                label="* Pais"
                v-model="country"
                :items="countries"
                item-text="name"
                item-value="name"
              ></v-select>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog = false">Cancelar</v-btn>
          <v-btn class="white--text" color="#FD5C01" @click="submit"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Perfil agregado con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

import { db } from "../../firebase";

interface Mall {
  id: string;
  code: string;
  name: string;
}

interface Country {
  id: string;
  code: string;
  name: string;
}

@Component
export default class AddComponent extends Vue {
  @Prop({ type: Array, required: true }) countries!: Country[];
  @Prop({ type: Array, required: true }) malls!: Mall[];

  dialog = false;
  snackbar = false;
  confirmAdd = false;
  snackbarTimeout = 3000;

  // Atributos
  code = "";
  tradename = "";
  businessname = "";
  identifier = "";
  shop = "";
  mall = "";
  country = "";

  // Metodos
  async addContracts() {
    try {
      if (
        this.code &&
        this.tradename &&
        this.businessname &&
        this.identifier &&
        this.shop &&
        this.mall &&
        this.country
      ) {
        // Genera un Nuevo Usuario en Firebase Firestore
        await db.collection("contratos").add({
          code: this.code,
          tradename: this.tradename,
          businessname: this.businessname,
          identifier: this.identifier,
          shop: this.shop,
          mall: this.mall,
          country: this.country,
        });

        // Emitir el evento de confirmación de eliminación exitosa
        this.emitConfirmAdd(true);

        this.snackbar = true;
        this.dialog = false;

        // Lipia variables
        this.code = "";
        this.tradename = "";
        this.businessname = "";
        this.identifier = "";
        this.shop = "";
        this.mall = "";
        this.country = "";
      } else {
        console.log("Campos Obligatorios");
      }
    } catch (error) {
      console.log(error);
    }
  }

  submit() {
    this.addContracts(); // Llama al método addContracts para enviar el formulario
  }

  // Emit que envía la confirmación de la agregación
  @Emit("confirmAdd")
  emitConfirmAdd(confirmAdd: boolean) {
    return confirmAdd;
  }
}
</script>
