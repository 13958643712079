<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="selectedUser.length > 0" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-btn v-else disabled icon v-bind="attrs" v-on="on">
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title> Información </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form>
              <v-text-field
                type="text"
                label="Usuario"
                v-model="name"
                outlined
                disabled
              ></v-text-field>

              <v-text-field
                type="text"
                label="Email"
                v-model="email"
                outlined
                disabled
              ></v-text-field>

              <v-text-field
                type="text"
                label="Estado"
                v-model="status"
                outlined
                disabled
              ></v-text-field>

              <v-text-field
                type="text"
                label="Nombres"
                v-model="names"
                outlined
                disabled
              ></v-text-field>

              <v-text-field
                type="text"
                label="Apellidos"
                v-model="surnames"
                outlined
                disabled
              ></v-text-field>

              <v-text-field
                type="text"
                label="Teléfono"
                v-model="phones"
                outlined
                disabled
              ></v-text-field>

              <v-text-field
                type="text"
                label="Cambiar Contraseña"
                v-model="changepassword"
                outlined
                disabled
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="white--text" color="#FD5C01" @click="dialog = false"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

interface User {
  name: string;
  email: string;
  status: string;
  names: string;
  surnames: string;
  phones: string;
  changepassword: string;
}

@Component
export default class VisualizeComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedUser!: User[];

  // Atributos
  name = "";
  email = "";
  status = "";
  names = "";
  surnames = "";
  phones = "";
  changepassword = "";

  // Variables
  dialog = false;

  get hasSelectedUser() {
    return this.selectedUser.length > 0;
  }

  @Watch("selectedUser")
  onSelectedUserChange(newVal: User[], oldVal: User[]) {
    if (newVal !== oldVal) {
      this.updateUserInfo();
    }
  }

  created() {
    this.updateUserInfo();
  }

  updateUserInfo() {
    if (this.hasSelectedUser) {
      const user = this.selectedUser[0];
      this.name = user.name;
      this.email = user.email;
      this.status = user.status;
      this.names = user.names;
      this.surnames = user.surnames;
      this.phones = user.phones;
      this.changepassword = user.changepassword;
    } else {
      // Si no hay usuario seleccionado, reiniciar valores
      this.resetUserInfo();
    }
  }

  resetUserInfo() {
    this.name = "";
    this.email = "";
    this.status = "";
    this.names = "";
    this.surnames = "";
    this.phones = "";
    this.changepassword = "";
  }
}
</script>
