<template>
  <div>
    <!-- {{ eventsSelected }} -->
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="eventsSelected"
      item-key="id"
      class="elevation-0"
    >
      <template slot="item.end" slot-scope="{ item }">
        {{ formatDate(item.end) }}
      </template>

      <template slot="item.amounts" slot-scope="{ item }">
        {{ formatAmounts(item.amounts) }}
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";

interface EventType {
  id: string;
  country: string;
  mall: string;
  registrant: string;
  shop: string;
  code: string;
  businessname: string;
  tradename: string;
  start: Date; //
  end: Date; //
  color: string;
  details: string; //
  operations: number; //
  amounts: number; //
}

@Component
export default class TableComponent extends Vue {
  @Prop({ type: Array, required: true }) eventsSelected!: EventType[];
  @Prop() readonly search!: string;

  singleSelect = true;
  selected = [];
  // Data
  headers = [
    {
      text: "Pais",
      align: "center",
      sortable: false,
      value: "country",
    },
    { text: "Complejo", align: "center", sortable: false, value: "mall" },
    {
      text: "Registrante",
      align: "center",
      sortable: false,
      value: "registrant",
    },
    { text: "Local", align: "center", sortable: false, value: "shop" },
    { text: "Contrato", align: "center", sortable: false, value: "code" },
    {
      text: "Razon Social",
      align: "center",
      sortable: false,
      value: "tradename",
    },
    {
      text: "Nombre Comercial",
      align: "center",
      sortable: false,
      value: "businessname",
    },
    {
      text: "Fecha",
      align: "center",
      sortable: true,
      value: "end",
    },
    {
      text: "Detalle",
      align: "center",
      sortable: false,
      value: "details",
    },
    {
      text: "Operaciones",
      align: "center",
      sortable: false,
      value: "operations",
    },
    {
      text: "Importe",
      align: "center",
      sortable: false,
      value: "amounts",
    },
  ];

  @Watch("selected")
  onSelectedChange(
    newValue: unknown
    //, oldValue: unknown
  ) {
    this.emitSelected(newValue);
  }

  @Emit("selected")
  emitSelected(selected: unknown) {
    return selected;
  }
  // Método computado para formatear la fecha
  formatDate(dateString: string): string {
    const parts = dateString.split("-");
    const date = parts[2] + "-" + parts[1] + "-" + parts[0];
    return date;
  }

  formatAmounts(amountsString: string) {
    // Convertir la cadena de montos a un número
    let amount = parseFloat(amountsString);

    // Verificar si el número es válido
    if (isNaN(amount)) {
      return "Cantidad no válida";
    }

    // Redondear el monto
    amount = Math.round(amount);

    // Formatear el número con separadores de miles y el símbolo de peso chileno
    const formattedAmount = new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }).format(amount);

    return formattedAmount;
  }
}
</script>
