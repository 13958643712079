<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="1200" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-import</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title>Importar</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-file-input
              accept=".xlsx"
              label="Excel"
              v-model="archivo"
              @change="handleFileUpload($event)"
            ></v-file-input>
          </v-container>
        </v-card-text>

        <v-card-text v-if="items.length > 0">
          <v-container>
            <v-data-table :headers="header" :items="items"></v-data-table>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="#FD5C01"
            @click="uploadItemsToFirestore"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Eventos Importados con Éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit } from "vue-property-decorator";
import * as XLSX from "xlsx";

import { db } from "../../firebase";
//import firebase from "firebase/compat/app";

@Component
export default class ImportXlsComponent extends Vue {
  archivo: Blob | null = null;
  header = [
    { text: "Pais", value: "country" },
    { text: "Complejo", value: "mall" },
    { text: "Registrante", value: "registrant" },
    { text: "Local", value: "shop" },
    { text: "Contrato", value: "code" },
    { text: "Razon Social", value: "businessname" },
    { text: "Nombre Comercial", value: "tradename" },
    { text: "Fecha", value: "date" },
    { text: "Detalle", value: "details" },
    { text: "Operaciones", value: "operations" },
    { text: "Importe", value: "amounts" },
  ];

  // eslint-disable-next-line
  items: any[] = [];

  confirmUpload = false;
  dialog = false;
  snackbar = false;
  snackbarTimeout = 3000;
  uid = "";

  // eslint-disable-next-line
  handleFileUpload(file: any) {
    if (file) {
      this.readFile(file);
    }
  }

  readFile(file: Blob) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target?.result;
      if (binaryString) {
        this.parseExcel(binaryString);
      }
    };
    reader.readAsBinaryString(file);
  }

  // eslint-disable-next-line
  parseExcel(binaryString: any) {
    const workbook = XLSX.read(binaryString, { type: "binary" });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];

    const data = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 2 });

    // Process in chunks
    const chunkSize = 500; // Adjust this value based on your performance tests
    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize);
      this.items = [...this.items, ...this.processChunk(chunk)];
    }
  }
  // eslint-disable-next-line
  processChunk(chunk: any[]) {
    // eslint-disable-next-line
    return chunk.map((row: any) => ({
      country: row[0],
      mall: row[1],
      registrant: row[2],
      shop: row[3],
      code: row[4],
      businessname: row[5],
      tradename: row[6],
      date: row[7],
      details: row[8],
      operations: row[9],
      amounts: row[10],
    }));
  }

  // eslint-disable-next-line
  //   this.items = data.map((row: any) => ({
  //     country: row[0],
  //     mall: row[1],
  //     registrant: row[2], //
  //     shop: row[3],
  //     code: row[4],
  //     businessname: row[5],
  //     tradename: row[6],
  //     date: row[7], //
  //     details: row[8], //
  //     operations: row[9], //
  //     amounts: row[10], //
  //   }));
  // }

  async uploadItemsToFirestore() {
    if (this.items.length > 0) {
      const chunkSize = 500; // Ajustar según pruebas de rendimiento
      for (let i = 0; i < this.items.length; i += chunkSize) {
        const chunk = this.items.slice(i, i + chunkSize);
        await this.uploadChunkToFirestore(chunk);
      }

      this.emitConfirmUpload(true);
      this.snackbar = true;
      this.dialog = false;
      this.items = [];
    } else {
      console.log("No hay elementos para subir");
    }
  }

  // eslint-disable-next-line
  async uploadChunkToFirestore(chunk: any[]) {
    await Promise.all(
      chunk.map(async (item) => {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const epochDate = new Date(1900, 0, 0);
        const daysToAdd = item.date - 1;
        const dateToAdd = new Date(
          epochDate.getTime() + daysToAdd * millisecondsPerDay
        );

        const yyyy = dateToAdd.getFullYear();
        const mm = String(dateToAdd.getMonth() + 1).padStart(2, "0");
        const dd = String(dateToAdd.getDate()).padStart(2, "0");
        const formattedDateString = `${yyyy}-${mm}-${dd}`;

        const codeFormatted = item.code.toString();

        db.collection("eventos").add({
          country: item.country,
          mall: item.mall,
          registrant: item.registrant,
          shop: item.shop,
          code: codeFormatted,
          businessname: item.businessname,
          tradename: item.tradename,
          start: formattedDateString,
          end: formattedDateString,
          color: item.amounts > 0 ? "green" : "orange",
          details: item.details,
          operations: item.operations,
          amounts: item.amounts,
        });
      })
    );
  }

  // async uploadItemsToFirestore() {
  //   if (this.items.length > 0) {
  //     await Promise.all(
  //       this.items.map(async (item) => {
  //         const millisecondsPerDay = 24 * 60 * 60 * 1000; // Milisegundos en un día
  //         const epochDate = new Date(1900, 0, 0); // Época Unix (1 de enero de 1970)
  //         const daysToAdd = item.date - 1; // Restamos 1 porque JavaScript comienza los días desde 1 de enero de 1970
  //         const dateToAdd = new Date(
  //           epochDate.getTime() + daysToAdd * millisecondsPerDay
  //         );

  //         const yyyy = dateToAdd.getFullYear();
  //         const mm = String(dateToAdd.getMonth() + 1).padStart(2, "0"); // Agrega ceros iniciales si es necesario
  //         const dd = String(dateToAdd.getDate()).padStart(2, "0"); // Agrega ceros iniciales si es necesario
  //         const formattedDateString = `${yyyy}-${mm}-${dd}`;

  //         const codeFormatted = item.code.toString();

  //         db.collection("eventos").add({
  //           country: item.country,
  //           mall: item.mall,
  //           registrant: item.registrant,
  //           shop: item.shop,
  //           code: codeFormatted,
  //           businessname: item.businessname,
  //           tradename: item.tradename,
  //           start: formattedDateString, //
  //           end: formattedDateString, //
  //           color: item.amounts > 0 ? "green" : "orange",
  //           details: item.details, //
  //           operations: item.operations, //
  //           amounts: item.amounts, //
  //         });
  //       })
  //     );

  //     this.emitConfirmUpload(true);
  //     this.snackbar = true;
  //     this.dialog = false;
  //     this.items = [];
  //   } else {
  //     console.log("No hay elementos para subir");
  //   }
  // }

  @Emit("confirmUpload")
  emitConfirmUpload(confirmUpload: boolean) {
    return confirmUpload;
  }
}
</script>
