<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltipOn }">
            <v-btn
              v-if="selectedItem.length > 0"
              color="success"
              icon
              v-bind="attrs"
              v-on="{ ...on, ...tooltipOn }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-else
              disabled
              icon
              v-bind="attrs"
              v-on="{ ...on, ...tooltipOn }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>

      <!-- Navegation Drawer -->
      <v-navigation-drawer
        temporary
        app
        clipped
        right
        v-model="dialog"
        width="400"
      >
        <v-card class="d-flex flex-column fill-height">
          <v-card-title class="ma-0 pa-0"
            ><v-toolbar rounded="0" flat dark color="#463e3b"
              >Editar
              <v-spacer></v-spacer>
              <v-btn icon color="white" @click="dialog = false">
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </v-toolbar></v-card-title
          >
          <v-card-text class="flex-grow-1 overflow-auto">
            <v-container class="my-6">
              <v-form
                @submit.prevent="submit"
                class="d-flex flex-column flex-grow-1"
              >
                <v-text-field
                  type="number"
                  label="Cantidad Operaciones"
                  v-model="operations"
                ></v-text-field>
                <v-text-field
                  type="number"
                  label="Importe Ventas"
                  v-model="amounts"
                ></v-text-field>
                <v-text-field
                  type="text"
                  label="Observaciones"
                  v-model="details"
                ></v-text-field>
              </v-form>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-row dense class="ma-1">
              <v-col md="6">
                <v-btn outlined block @click="dialog = false">Cancelar</v-btn>
              </v-col>
              <v-col md="6">
                <v-btn block class="white--text" color="#FD5C01" @click="submit"
                  >Aceptar</v-btn
                >
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-navigation-drawer>
    </v-dialog>

    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Operación editado con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";

import { db } from "../../firebase";
interface EventType {
  id: string;
  country: string;
  mall: string;
  registrant: string;
  shop: string;
  code: string;
  businessname: string;
  tradename: string;
  start: string; //
  end: string; //
  color: string;
  details: string; //
  operations: number; //
  amounts: number; //
}
@Component
export default class EditOperationsComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedItem!: EventType[];

  // Atributos
  id = "";
  country = "";
  mall = "";
  registrant = "";
  shop = "";
  code = "";
  businessname = "";
  tradename = "";
  start = null;
  end = null;
  color = "#1976D2";
  details = "";
  operations = 0;
  amounts = 0;
  // Variables
  dialog = false;
  snackbar = false;
  snackbarTimeout = 3000;

  async editItem(itemId: string) {
    try {
      if (this.amounts && this.operations && this.details) {
        await db.collection("eventos").doc(itemId).set({
          // Atributos a agregar a la bd
          id: this.id,
          country: this.country,
          mall: this.mall,
          registrant: this.registrant,
          shop: this.shop,
          code: this.code,
          businessname: this.businessname,
          tradename: this.tradename,
          start: this.start,
          end: this.end,
          color: this.color,
          details: this.details,
          operations: this.operations,
          amounts: this.amounts,
        });

        // Emitir el evento de confirmación de eliminación exitosa
        this.emitConfirmEdit(true);

        this.snackbar = true;
        this.dialog = false;
      } else {
        console.log("Campos Obligatorios");
      }
    } catch (error) {
      console.log(error);
    }
  }

  submit() {
    this.editItem(this.id);
  }

  // Emit que envía la confirmación de la agregación
  @Emit("confirmEdit")
  emitConfirmEdit(confirmEdit: boolean) {
    return confirmEdit;
  }

  @Watch("selectedItem")
  onSelectedChange() {
    // eslint-disable-next-line
    for (const item of this.selectedItem as any[]) {
      this.id = item.id;
      this.country = item.country;
      this.mall = item.mall;
      this.registrant = item.registrant;
      this.shop = item.shop;
      this.code = item.code;
      this.businessname = item.businessname;
      this.tradename = item.tradename;
      this.start = item.start;
      this.end = item.end;
      this.color = item.color;
      this.details = item.details; //
      this.operations = item.operations; //
      this.amounts = item.amounts; //
    }
  }
}
</script>
