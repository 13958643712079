<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" md="9" class="px-0 pb-0 d-none d-md-flex">
        <v-img
          height="100vh"
          src="../../public/img/fondo.jpg"
          :style="{ height: '100vh', width: '100%' }"
          cover
        ></v-img
      ></v-col>

      <v-col cols="12" md="3" class="px-0">
        <v-card class="pa-3" elevation="0">
          <v-row no-gutters class="pa-6">
            <v-col class="d-flex justify-center align-center">
              <v-img
                src="../../public/img/logoFloridaGo.png"
                :style="{
                  width: 'auto',
                  height: 'auto',
                }"
                cover
              ></v-img>
            </v-col>
          </v-row>

          <v-row no-gutters class="px-6 py-1" justify="center">
            <v-col cols="auto">
              <h2>Iniciar Sesión</h2>
            </v-col>
          </v-row>

          <v-row class="px-6 py-1" align="center">
            <v-col cols="12"> Usuario </v-col>
          </v-row>

          <v-row no-gutters class="px-6 py-1" align="center">
            <v-col cols="12">
              <v-text-field
                v-model="email"
                placeholder="Nombre de usuario / email"
                type="email"
                required
                outlined
              />
            </v-col>
          </v-row>

          <v-row no-gutters class="px-6 py-1" align="center">
            <v-col cols="12"> Contraseña </v-col>
          </v-row>
          <v-row no-gutters class="px-6 py-1" align="center">
            <v-col cols="12">
              <v-text-field
                v-model="password"
                outlined
                placeholder="******"
                type="password"
                required
              />
            </v-col>
          </v-row>

          <v-row no-gutters class="px-6 py-1" align="end" justify="center">
            <v-col cols="12">
              <v-btn
                @click="login"
                class="white--text text-initial font-weight-bold"
                color="#FD5C01"
                depressed
                block
              >
                Iniciar sesión
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="px-6 py-1" align="center">
            <v-col cols="12" class="text-body-2">
              ¿Olvidaste tu contraseña?
              <router-link to="/">Haz click aquí</router-link>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import firebase from "firebase/compat/app";
import router from "@/router";

export default defineComponent({
  name: "LoginView",
  data() {
    return {
      email: "",
      password: "",
      loggedIn: false,
    };
  },
  methods: {
    async login() {
      // Define los tipos para el método login
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(
            (user) => {
              console.log(user);
              this.loggedIn = true;
              router.push("/").then(() => {
                window.location.reload();
              });
            },
            (err) => {
              alert(err);
            }
          );
      } catch (erro) {
        console.error("Error al iniciar sesión:", erro);
        alert("Error al iniciar sesión. Por favor, inténtalo de nuevo.");
      }
    },
  },
});
</script>
