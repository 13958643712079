import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDo-YdLs7QHpn4vP286AKNfYNz9VDfJAN4",
  authDomain: "sistema-ventas-b53ba.firebaseapp.com",
  projectId: "sistema-ventas-b53ba",
  storageBucket: "sistema-ventas-b53ba.appspot.com",
  messagingSenderId: "506886979421",
  appId: "1:506886979421:web:0db97518774536a1bd72b4",
};

// Inicializar Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();

export default firebase;
