<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="1400" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltipOn }">
            <v-btn
              v-bind="attrs"
              v-on="{ ...on, ...tooltipOn }"
              class="white--text text-initial font-weight-bold"
              color="#FD5C01"
              block
            >
              Agregar
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title> Agregar </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form>
              <v-text-field
                type="text"
                label="* UID"
                v-model="uid"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Código"
                v-model="code"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Usuario"
                v-model="name"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Correo"
                v-model="email"
              ></v-text-field>
              <v-text-field
                type="password"
                label="* Clave"
                v-model="password"
              ></v-text-field>
              <v-select
                type="text"
                label="* Estado"
                v-model="status"
                :items="itemsStatus"
              ></v-select>
              <v-text-field
                type="text"
                label="* Nombres"
                v-model="names"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Apellidos"
                v-model="surnames"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Teléfonos"
                v-model="phones"
              ></v-text-field>
              <v-select
                type="text"
                label="* Cambiar Clave"
                v-model="changepassword"
                :items="itemsChangepassword"
              ></v-select>
            </v-form>
            <v-row class="my-4"><v-divider></v-divider></v-row>
            <v-row>
              <v-col>
                <h3>Perfil</h3>
              </v-col>
            </v-row>
            <v-row class="my-4"
              ><v-col
                ><table-component-profiles
                  :profiles="profiles"
                  @selected="handleSelectedProfile"
                ></table-component-profiles></v-col
            ></v-row>
            <v-row class="my-4"><v-divider></v-divider></v-row>
            <v-row>
              <v-col>
                <h3>Contratos</h3>
              </v-col>
            </v-row>
            <v-row class="my-4"
              ><v-col
                ><table-component-contracts
                  :contracts="contracts"
                  @selected="handleSelected"
                ></table-component-contracts></v-col
            ></v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog = false">Cancelar</v-btn>
          <v-btn class="white--text" color="#FD5C01" @click="submit"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Usuario agregado con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import TableComponentContracts from "../Users/TableComponentContacts.vue";
import TableComponentProfiles from "../Users/TableComponentProfiles.vue";

import { db } from "../../firebase";

@Component({
  components: {
    TableComponentContracts,
    TableComponentProfiles,
  },
})
export default class AddComponent extends Vue {
  @Prop({ type: Array, required: true }) contracts!: unknown[];
  @Prop({ type: Array, required: true }) profiles!: unknown[];

  // Variables
  dialog = false;
  snackbar = false;
  confirmAdd = false;
  snackbarTimeout = 3000;
  itemsChangepassword = ["SI", "NO"];
  itemsStatus = ["HABILITADO", "BLOQUEADO", "DESHABILITADO"];

  // Atributos
  uid = "";
  code = "";
  name = "";
  email = "";
  password = "";
  status = "";
  names = "";
  surnames = "";
  phones = "";
  changepassword = "";
  contractsSelected = [];
  profileSelected = [];

  // Metodos
  async addUser() {
    try {
      if (this.email.trim() !== "" && this.password.trim() !== "") {
        await db.collection("usuarios").add({
          code: this.code,
          name: this.name,
          email: this.email,
          password: this.password,
          status: this.status,
          names: this.names,
          surnames: this.surnames,
          phones: this.phones,
          changepassword: this.changepassword,
          uid: this.uid,
          contractsSelected: this.contractsSelected,
          profileSelected: this.profileSelected,
        });

        // Emitir el evento de confirmación de eliminación exitosa
        this.emitConfirmAdd(true);
        this.snackbar = true;
        this.dialog = false;

        // Lipia variables
        this.code = "";
        this.name = "";
        this.email = "";
        this.password = "";
        this.status = "";
        this.names = "";
        this.surnames = "";
        this.phones = "";
        this.changepassword = "";
        this.uid = "";
        this.contractsSelected = [];
        this.profileSelected = [];
      } else {
        console.log("Campos Obligatorios");
      }
    } catch (error) {
      console.log(error);
    }
  }

  submit() {
    this.addUser();
  }

  handleSelected(selected: []) {
    this.contractsSelected = selected;
  }

  handleSelectedProfile(selected: []) {
    this.profileSelected = selected;
  }

  @Emit("confirmAdd")
  emitConfirmAdd(confirmAdd: boolean) {
    return confirmAdd;
  }
}
</script>
