<template>
  <div class="text-center">
    <v-btn
      v-if="contractSelected.length > 0"
      class="white--text text-initial font-weight-bold"
      color="#FD5C01"
      block
      @click="process"
    >
      Procesar Información
    </v-btn>
    <v-btn
      v-else
      disabled
      class="white--text text-initial font-weight-bold"
      color="#FD5C01"
      block
      @click="process"
    >
      Procesar Información
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import { db } from "../../firebase";

interface EventType {
  id: string;
  country: string;
  mall: string;
  registrant: string;
  shop: string;
  code: string;
  businessname: string;
  tradename: string;
  start: Date;
  end: Date;
  color: string;
  details: string;
  operations: number;
  amounts: number;
}

interface Contracts {
  code: string;
  tradename: string;
  identifier: string;
  businessname: string;
  shop: string;
  mall: string;
  country: string;
}

@Component
export default class ProcessInformationComponent extends Vue {
  @Prop() contractSelected!: Contracts[];
  @Prop() dateStartSelected!: string;
  @Prop() dateEndSelected!: string;

  events: EventType[] = [];

  ArrayCode(): string[] {
    const contractCodes = this.contractSelected.map(
      (contract) => contract.code
    );
    return contractCodes;
  }

  // async process() {
  //   try {
  //     console.log("** Elementos a Procesar **");
  //     // Obtener solo los códigos de los contratos seleccionados

  //     console.log("Códigos de contrato seleccionados:", this.ArrayCode());
  //     console.log("Contrato: ", this.contractSelected);
  //     console.log("Desde: ", this.dateStartSelected);
  //     // Parsea la fecha de string a date
  //     const startDate = new Date(this.dateStartSelected + "T00:00:00");
  //     console.log(startDate);
  //     // Parsea la fecha de string a date
  //     console.log("Hasta: ", this.dateEndSelected);
  //     const endDate = new Date(this.dateEndSelected + "T00:00:00");
  //     console.log(endDate);

  //     // if (this.contractSelected === "") {
  //     const snapshot = await db
  //       .collection("eventos")
  //       .where("code", "in", this.ArrayCode())
  //       .get();
  //     this.events = snapshot.docs
  //       .map((doc) => {
  //         const eventoData = doc.data() as EventType;
  //         eventoData.id = doc.id;
  //         console.log(">", eventoData.start);
  //         console.log(">>", new Date(eventoData.start + "T00:00:00"));

  //         // Parsear el campo "start" como una fecha
  //         eventoData.start = new Date(eventoData.start + "T00:00:00");
  //         eventoData.end = new Date(eventoData.end + "T00:00:00");

  //         if (startDate <= eventoData.start && endDate >= eventoData.end) {
  //           eventoData.start = startDate;
  //           return eventoData;
  //         } else {
  //           return null;
  //         }
  //       })
  //       .filter((evento): evento is EventType => evento !== null);
  //     console.log("Evento", this.events);
  //     // } else {
  //     //   const snapshot = await db
  //     //     .collection("eventos")
  //     //     .where("code", "==", this.contractSelected)
  //     //     .get();
  //     //   this.events = snapshot.docs.map((doc) => {
  //     //     const eventoData = doc.data() as EventType;
  //     //     eventoData.id = doc.id;
  //     //     return eventoData;
  //     //   });
  //     // }

  //     console.log("Eventos: ", this.events);
  //     this.emitEvents();
  //   } catch (error) {
  //     console.error("Error al procesar la información:");
  //   }
  // }

  async process() {
    try {
      console.log("** Elementos a Procesar **");
      const contractCodes = this.ArrayCode();
      console.log("Códigos de contrato seleccionados:", contractCodes);
      console.log("Contrato: ", this.contractSelected);
      console.log("Desde: ", this.dateStartSelected);
      const startDate = new Date(this.dateStartSelected + "T00:00:00");
      console.log(startDate);
      console.log("Hasta: ", this.dateEndSelected);
      const endDate = new Date(this.dateEndSelected + "T00:00:00");
      console.log(endDate);

      const batchSize = 500; // Tamaño del lote para paginación

      // Dividir los códigos en bloques de 30
      const chunks = this.chunkArray(contractCodes, 30);

      for (const chunk of chunks) {
        let lastVisible = null;
        let hasMore = true;

        while (hasMore) {
          let query = db
            .collection("eventos")
            .where("code", "in", chunk)
            .orderBy("start")
            .limit(batchSize);

          if (lastVisible) {
            query = query.startAfter(lastVisible);
          }

          const snapshot = await query.get();
          if (snapshot.empty) {
            hasMore = false;
            break;
          }

          this.events.push(
            ...snapshot.docs
              .map((doc) => {
                const eventoData = doc.data() as EventType;
                eventoData.id = doc.id;
                console.log("***", eventoData.start);
                // eventoData.start = new Date(eventoData.start);

                // console.log(">>>", eventoData.start);

                // Crear una nueva fecha ajustando el desfase manualmente
                let eventoDataStartString = eventoData.start.toString();
                let parts = eventoDataStartString.split("-");
                let correctedDateStart = new Date(
                  parseInt(parts[0]),
                  parseInt(parts[1]) - 1,
                  parseInt(parts[2])
                );
                console.log(">>>", correctedDateStart);

                // eventoData.end = new Date(eventoData.end);

                // Crear una nueva fecha ajustando el desfase manualmente
                let eventoDataEndString = eventoData.end.toString();
                let partsEnd = eventoDataEndString.split("-");
                let correctedDateEnd = new Date(
                  parseInt(partsEnd[0]),
                  parseInt(partsEnd[1]) - 1,
                  parseInt(partsEnd[2])
                );
                console.log(">>>", correctedDateEnd);

                if (
                  startDate <= correctedDateStart &&
                  endDate >= correctedDateEnd
                ) {
                  return eventoData;
                } else {
                  return null;
                }
              })
              .filter((evento): evento is EventType => evento !== null)
          );

          lastVisible = snapshot.docs[snapshot.docs.length - 1];
        }
      }

      console.log("Eventos: ", this.events);
      this.emitEvents();
    } catch (error) {
      console.error("Error al procesar la información:", error);
    }
  }

  chunkArray(arr: string[], size: number): string[][] {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  }

  @Emit("events")
  emitEvents() {
    return this.events;
  }
}
</script>
