<template>
  <div class="text-center">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="white--text text-initial font-weight-bold"
          color="#FD5C01"
          block
          @click="drawerRight = !drawerRight"
        >
          Agregar
        </v-btn>
      </template>

      <span>Agregar</span>
    </v-tooltip>

    <v-navigation-drawer
      v-if="!isMobile"
      temporary
      app
      clipped
      right
      v-model="drawerRight"
      width="400"
    >
      <v-card class="d-flex flex-column fill-height">
        <v-card-title class="ma-0 pa-0"
          ><v-toolbar rounded="0" flat dark color="#463e3b"
            >Agregar
            <v-spacer></v-spacer>
            <v-btn icon color="white" @click="drawerRight = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-toolbar></v-card-title
        >
        <v-card-text class="flex-grow-1 overflow-auto">
          <v-container class="my-6">
            <v-form
              @submit.prevent="addEvent"
              class="d-flex flex-column flex-grow-1"
            >
              <v-text-field
                type="number"
                label="Cantidad Operaciones"
                v-model="operations"
              ></v-text-field>
              <v-text-field
                type="number"
                label="Importe Ventas"
                v-model="amounts"
              ></v-text-field>
              <v-text-field
                type="text"
                label="Observaciones"
                v-model="details"
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row dense class="ma-1">
            <v-col md="6">
              <v-btn outlined block @click="drawerRight = false"
                >Cancelar</v-btn
              >
            </v-col>
            <v-col md="6">
              <v-btn block class="white--text" color="#FD5C01" @click="addEvent"
                >Aceptar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
    <!-- Dialog para dispositivos móviles -->
    <v-dialog v-if="isMobile" v-model="drawerRight" max-width="500" persistent>
      <v-card>
        <v-card-title class="ma-0 pa-0">
          <v-toolbar rounded="0" flat dark color="#463e3b">
            Agregar
            <v-spacer></v-spacer>
            <v-btn icon color="white" @click="drawerRight = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <v-container class="my-6">
            <v-form @submit.prevent="addEvent">
              <!-- Campos del formulario -->
              <v-text-field
                type="number"
                label="Cantidad Operaciones"
                v-model="operations"
              ></v-text-field>
              <v-text-field
                type="number"
                label="Importe Ventas"
                v-model="amounts"
              ></v-text-field>
              <v-text-field
                type="text"
                label="Observaciones"
                v-model="details"
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row dense class="ma-1">
            <v-col md="6">
              <v-btn outlined block @click="drawerRight = false">
                Cancelar
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-btn
                block
                class="white--text"
                color="#FD5C01"
                @click="addEvent"
              >
                Aceptar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Registración agregada con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

import { db } from "../../firebase";

// interface EventType {
//   id: string;
//   country: string;
//   mall: string;
//   registrant: string;
//   shop: string;
//   code: string;
//   businessname: string;
//   tradename: string;
//   start: Date; //
//   end: Date; //
//   color: string;
//   details: string; //
//   operations: number; //
//   amounts: number; //
// }

interface Contracts {
  code: string;
  tradename: string;
  identifier: string;
  businessname: string;
  shop: string;
  mall: string;
  country: string;
}

interface User {
  code: string;
  names: string;
  contractsSelected: string[];
  // otras propiedades de currentUser si las hay
}

@Component({})
export default class AddComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedValue!: Contracts[];
  @Prop() focus!: string;

  @Prop() currentUser!: User;

  created() {
    console.log(focus);
    console.log(this.selectedValue);
  }

  // focus = "";
  //
  drawerRight = false;
  snackbar = false;
  confirmAdd = false;
  snackbarTimeout = 3000;

  // Atributos
  contracts = "";
  code = "";
  // name = "";
  amounts = 0;
  operations = 0;
  details = "";
  start = null;
  end = null;
  color = "#1976D2";

  get isMobile() {
    return window.innerWidth <= 600;
  }

  toggleDrawerOrDialog() {
    this.drawerRight = !this.drawerRight;
  }

  // Metodos
  async addEvent() {
    try {
      if (this.amounts && this.operations && this.details && this.focus) {
        await db.collection("eventos").add({
          country: this.selectedValue[0].country,
          mall: this.selectedValue[0].mall,
          registrant: this.currentUser.names,
          shop: this.selectedValue[0].shop,
          code: this.selectedValue[0].code,
          businessname: this.selectedValue[0].businessname,
          tradename: this.selectedValue[0].tradename,
          start: this.focus,
          end: this.focus,
          color: this.amounts > 0 ? "green" : "orange",
          details: this.details.toUpperCase(), //
          operations: this.operations, //
          amounts: this.amounts, //
        });

        // Emitir el evento de confirmación de eliminación exitosa
        this.emitConfirmAdd(true);
        this.snackbar = true;
        this.drawerRight = false;

        // Lipia variables
        this.contracts = "";
        // this.name = "";
        this.amounts = 0;
        this.operations = 0;
        this.details = "";
        this.start = null;
        this.end = null;
        this.color = "#1976D2";
      } else {
        console.log("Campos Obligatorios");
      }
    } catch (error) {
      console.log(error);
    }
  }

  submit() {
    this.addEvent(); // Llama al método addEvent para enviar el formulario
  }

  // Emit que envía la confirmación de la agregación
  @Emit("confirmAdd")
  emitConfirmAdd(confirmAdd: boolean) {
    return confirmAdd;
  }
}
</script>
