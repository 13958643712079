<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="selectedItem.length > 0" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-information</v-icon>
        </v-btn>
        <v-btn v-else disabled icon v-bind="attrs" v-on="on">
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title> Información </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form>
              <v-text-field
                type="text"
                label="Código"
                v-model="code"
                outlined
                disabled
              ></v-text-field>

              <v-text-field
                type="text"
                label="Nombre"
                v-model="name"
                outlined
                disabled
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="white--text" color="#FD5C01" @click="dialog = false"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

interface Country {
  code: string;
  name: string;
}

@Component
export default class VisualizeComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedItem!: Country[];

  // Atributos
  code = "";
  name = "";
  // Variables
  dialog = false;

  get hasSelectedItem() {
    return this.selectedItem.length > 0;
  }

  @Watch("selectedItem")
  onSelectedItemChange(newVal: Country[], oldVal: Country[]) {
    if (newVal !== oldVal) {
      this.updateInfo();
    }
  }

  created() {
    this.updateInfo();
  }

  updateInfo() {
    if (this.hasSelectedItem) {
      const item = this.selectedItem[0];
      this.code = item.code;
      this.name = item.name;
    } else {
      // Si no hay usuario seleccionado, reiniciar valores
      this.resetInfo();
    }
  }

  resetInfo() {
    this.code = "";
    this.name = "";
  }
}
</script>
