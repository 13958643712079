<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <!-- <v-btn
            v-if="focus !== ''"
            outlined
            class="mr-4"
            color="primary"
            @click="dialog = true"
          >
            Agregar
          </v-btn>
          <v-btn
            v-else
            disabled
            outlined
            class="mr-4"
            color="primary"
            @click="dialog = true"
          >
            Agregar
          </v-btn> -->
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Hoy
          </v-btn>
          <v-btn outlined color="black" @click="prev">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn outlined color="black" @click="next">
            <v-icon> mdi-chevron-right </v-icon>
          </v-btn>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="800">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-height="50"
          :event-margin-bottom="2"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          locale="es"
          :short-weekdays="true"
          :weekdays="weekday"
        >
          <template v-slot:event="{ event }">
            <div class="text-right ma-1">
              <h3>{{ getEventOperations(event) }}</h3>
              <h3>{{ getEventAmounts(event) }}</h3>
            </div>
          </template>
        </v-calendar>

        <!-- Modal Agregar Evento -->
        <!-- <v-navigation-drawer
          temporary
          app
          clipped
          right
          v-model="dialog"
          width="400"
        >
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="ma-0 pa-0"
              ><v-toolbar rounded="0" flat dark color="#463e3b"
                >Agregar
                <v-spacer></v-spacer>
                <v-btn icon color="white" @click="dialog = false">
                  <v-icon color="white">mdi-close</v-icon>
                </v-btn>
              </v-toolbar></v-card-title
            >
            <v-card-text class="flex-grow-1 overflow-auto">
              <v-container class="my-6">
                <v-form
                  @submit.prevent="addEvent"
                  class="d-flex flex-column flex-grow-1"
                >
                  <v-text-field
                    type="number"
                    label="Cantidad Operaciones"
                    v-model="operations"
                  ></v-text-field>
                  <v-text-field
                    type="number"
                    label="Importe Ventas"
                    v-model="amounts"
                  ></v-text-field>
                  <v-text-field
                    type="text"
                    label="Observaciones"
                    v-model="details"
                  ></v-text-field>
                </v-form>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-row dense class="ma-1">
                <v-col md="6">
                  <v-btn outlined block @click="dialog = false">Cancelar</v-btn>
                </v-col>
                <v-col md="6">
                  <v-btn
                    block
                    class="white--text"
                    color="#FD5C01"
                    @click="addEvent"
                    >Aceptar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-navigation-drawer> -->

        <!-- <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
        </v-menu> -->
      </v-sheet>
      <v-divider></v-divider>
      <!-- <v-row>{{ this.focus }}</v-row> -->

      <v-card color="#E8E8E8" rounded="0" outlined class="my-3">
        <v-row class="my-1">
          <v-col cols="3" class="text-center">
            <div>Total Operaciones:</div>
          </v-col>
          <v-col cols="3" class="text-center">
            <div>{{ this.totalOperaciones }}</div>
          </v-col>
          <v-col cols="3" class="text-center">
            <div>Total Importe Ventas:</div>
          </v-col>
          <v-col cols="3" class="text-center">
            <div>{{ this.totalVentas }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { db } from "../../firebase";
import Vue from "vue";

interface Contracts {
  code: string;
  tradename: string;
  identifier: string;
  businessname: string;
  shop: string;
  mall: string;
  country: string;
}

interface EventType {
  id: string;
  country: string;
  mall: string;
  registrant: string;
  shop: string;
  code: string;
  businessname: string;
  tradename: string;
  start: string; //
  end: string; //
  color: string;
  details: string; //
  operations: number; //
  amounts: number; //
}

export default Vue.extend({
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null as HTMLElement | null,
    // selectedOpen: false,
    events: [] as EventType[],

    // Adicionales
    // calendarTitle: "",

    contracts: "",
    code: "",
    name: "",
    amounts: 0,
    operations: 0,
    details: "",
    //
    // country: "",
    // mall: "",
    // registrant: "",
    // local:"",
    // contrato
    // razon social
    // nombre comercial
    // fecha
    // numeracion
    // operations
    // importe

    color: "#1976D2",
    start: null,
    end: null,
    dialog: false,
    currentlyEditing: null,
    //
    weekday: [1, 2, 3, 4, 5, 6, 0],
    //
    totalOperaciones: "0",
    totalVentas: "0",
  }),
  mounted() {
    // eslint-disable-next-line
    (this.$refs.calendar as any).checkChange();
  },
  created() {
    this.$watch("calendarUpdate", this.getEvent);
    // Observe los cambios en selectedItem
    this.$watch("selectedItem", this.getEvent);
    // this.getEvent();
  },
  methods: {
    async addEvent() {
      try {
        if (this.amounts && this.operations && this.details && this.focus) {
          await db.collection("eventos").add({
            country: this.selectedItem[0].country,
            mall: this.selectedItem[0].mall,
            registrant: "",
            shop: this.selectedItem[0].shop,
            code: this.selectedItem[0].code,
            businessname: this.selectedItem[0].businessname,
            tradename: this.selectedItem[0].tradename,
            start: this.focus,
            end: this.focus,
            color: this.amounts > 0 ? "green" : "orange",
            details: this.details.toUpperCase(), //
            operations: this.operations, //
            amounts: this.amounts, //
          });

          this.getEvent();

          // Lipia variables
          this.contracts = "";
          this.name = "";
          this.amounts = 0;
          this.operations = 0;
          this.details = "";
          this.start = null;
          this.end = null;
          this.color = "#1976D2";
          this.dialog = false;
        } else {
          console.log("Campos Obligatorios");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getEvent() {
      try {
        // const snapshot = await db.collection("eventos").get();
        const snapshot = await db
          .collection("eventos")
          .where("code", "==", this.selectedItem[0].code)
          .get();
        const events: EventType[] = [];

        // Variables de Totales Operaciones y Ventas
        let totalOperaciones = 0;
        let totalVentas = 0;

        const selectedMonth = (() => {
          const monthNames = [
            "enero",
            "febrero",
            "marzo",
            "abril",
            "mayo",
            "junio",
            "julio",
            "agosto",
            "septiembre",
            "octubre",
            "noviembre",
            "diciembre",
          ];
          // eslint-disable-next-line
          const [month, year] = (this.$refs.calendar as any).title.split(" "); // Separar mes y año
          console.log([month, year]);
          //
          // Obtener el índice del mes
          const monthIndex = monthNames.findIndex(
            (monthName) => monthName.toLowerCase() === month.toLowerCase()
          );
          if (monthIndex === -1) {
            console.error("Nombre de mes no válido:", month);
            return null; // Devolver un valor nulo si el nombre del mes no es válido
          }

          // Formatear el mes como "YYYY-MM"
          const formattedMonth =
            year + "-" + ("0" + (monthIndex + 1)).slice(-2);
          console.log(formattedMonth);

          return formattedMonth;
        })();

        snapshot.forEach((doc) => {
          let eventoData = doc.data() as EventType;
          eventoData.id = doc.id;

          const eventMonth = eventoData.start.substring(0, 7); // Obtener el mes del evento en formato "YYYY-MM"
          if (eventMonth === selectedMonth) {
            // Sumar las operaciones y ventas solo para el mes seleccionado
            totalOperaciones += Number(eventoData.operations) || 0;
            totalVentas += Number(eventoData.amounts) || 0;

            events.push(eventoData);
          }
        });

        this.events = events;
        //
        this.totalOperaciones = new Intl.NumberFormat("es-CL", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(totalOperaciones);

        this.totalVentas = new Intl.NumberFormat("es-CL", {
          style: "currency",
          currency: "CLP",
        }).format(totalVentas);
      } catch (error) {
        console.log(error);
      }
    },

    viewDay({ date }: { date: string }) {
      this.focus = date;
      this.type = "month";
    },
    getEventColor(event: EventType) {
      return event.color;
    },

    getEventAmounts(event: EventType) {
      const formattedAmount = new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(event.amounts);
      return formattedAmount;
    },

    getEventOperations(event: EventType) {
      return event.operations;
    },
    emitFocus() {
      this.$emit("focus", this.focus);
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      // eslint-disable-next-line
      (this.$refs.calendar as any).prev();
    },
    next() {
      // eslint-disable-next-line
      (this.$refs.calendar as any).next();
    },
    showEvent({
      nativeEvent,
    }: // event,
    {
      nativeEvent: MouseEvent;
      event: EventType;
    }) {
      // const open = () => {
      //   this.selectedEvent = event;
      //   this.selectedElement = nativeEvent.target as HTMLElement;
      //   requestAnimationFrame(() =>
      //     requestAnimationFrame(() => (this.selectedOpen = true))
      //   );
      // };

      // if (this.selectedOpen) {
      //   this.selectedOpen = false;
      //   requestAnimationFrame(() => requestAnimationFrame(() => open()));
      // } else {
      //   open();
      // }

      nativeEvent.stopPropagation();
    },
    // eslint-disable-next-line
    updateRange({ start, end }: { start: Date; end: Date }) {
      const events: EventType[] = [];
      this.events = events;
    },
    // rnd(a, b) {
    //   return Math.floor((b - a + 1) * Math.random()) + a;
    // },
  },
  props: {
    // Especifica el tipo del prop selectedItem como un objeto
    selectedItem: {
      type: Array as () => Contracts[],
    },
    calendarUpdate: { type: Boolean },
  },
  watch: {
    //this.emitFocus();
    focus(newDate, oldDate) {
      if (newDate !== oldDate) {
        //this.emitFocus();

        this.getEvent();
      }
      this.emitFocus();
    },
  },
});
</script>
