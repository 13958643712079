<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="contracts"
      :single-select="singleSelect"
      item-key="id"
      show-select
      class="elevation-0"
    >
    </v-data-table>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";

interface Contract {
  id: string;
  code: string;
  tradename: string;
  businessname: string;
  identifier: string;
  mall: string;
  shop: string;
  country: string;
}

@Component
export default class TableComponent extends Vue {
  @Prop({ type: Array, required: true }) contracts!: Contract[];
  @Prop({ type: Array, required: true }) contractsSelected!: string[];
  @Prop() readonly search!: string;

  singleSelect = false;
  selected: Contract[] = [];

  headers = [
    { text: "Codigo", align: "center", sortable: false, value: "code" },
    {
      text: "Nombre Comercial",
      align: "center",
      sortable: false,
      value: "tradename",
    },
    {
      text: "Razón Social",
      align: "center",
      sortable: false,
      value: "businessname",
    },
    {
      text: "Identificación",
      align: "center",
      sortable: false,
      value: "identifier",
    },
    {
      text: "Centro Comercial",
      align: "center",
      sortable: false,
      value: "mall",
    },
    { text: "Local", align: "center", sortable: false, value: "shop" },
    { text: "País", align: "center", sortable: false, value: "country" },
  ];

  created() {
    // Inicializar 'selected' con los contratos seleccionados
    this.selected = this.contracts.filter((contract) =>
      this.contractsSelected.includes(contract.code)
    );
  }

  @Watch("selected")
  onSelectedChange(
    // eslint-disable-next-line
    newValue: Contract[]
    //, oldValue: unknown
  ) {
    // eslint-disable-next-line
    const selectedIds = newValue.map((item) => item.code);
    this.emitSelected(selectedIds);
    console.log("IDs seleccionados: ", selectedIds);
  }

  @Emit("selected")
  emitSelected(selected: string[]) {
    return selected;
  }
}
</script>
