<template>
  <v-container fluid>
    <v-row dense class="ma-4">
      <v-col>
        <h1>Perfiles</h1>
      </v-col>
    </v-row>

    <v-row dense justify="center" class="mx-4">
      <v-col>
        <v-text-field
          outlined
          label="Busqueda"
          v-model="search"
          dense
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6"></v-col>

      <v-col cols="auto" md="auto">
        <visualize-component
          :selectedItem="selectedValue"
        ></visualize-component>
      </v-col>

      <v-col cols="auto" md="auto">
        <download-component :selectedItem="selectedValue"></download-component>
      </v-col>

      <v-col cols="auto" md="auto">
        <edit-component
          :selectedItem="selectedValue"
          @confirmEdit="confirmEdit"
        ></edit-component>
      </v-col>

      <v-col cols="auto" md="auto">
        <delete-component
          :selectedItem="selectedValue"
          @confirmDelete="confirmDelete"
        ></delete-component>
      </v-col>
      <v-col cols="12" md="auto" class="ma-1"> </v-col>

      <v-col cols="12" md="2">
        <add-component @confirmAdd="confirmAdd"></add-component>
      </v-col>
    </v-row>

    <v-row dense justify="center" class="mx-4">
      <v-divider></v-divider>
    </v-row>

    <v-row dense justify="center" class="pa-4">
      <v-col>
        <table-component
          :search="search"
          :profiles="profiles"
          @selected="handleSelected"
        ></table-component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import DownloadComponent from "../components/Profiles/DownloadComponent.vue";
import VisualizeComponent from "../components/Profiles/VisualizeComponent.vue";
import EditComponent from "../components/Profiles/EditComponent.vue";
import DeleteComponent from "../components/Profiles/DeleteComponent.vue";
import AddComponent from "../components/Profiles/AddComponent.vue";
import TableComponent from "../components/Profiles/TableComponent.vue";

import { db } from "../firebase";

export default Vue.extend({
  name: "ProfilesView",

  data: () => ({
    profiles: [],
    selectedValue: [],
    confirmed: false,
    search: "",
  }),

  components: {
    DownloadComponent,
    VisualizeComponent,
    EditComponent,
    DeleteComponent,
    AddComponent,
    TableComponent,
  },

  created() {
    this.getProfiles();
  },
  methods: {
    async getProfiles() {
      try {
        const snapshot = await db.collection("perfiles").get();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const profiles: any = [];

        snapshot.forEach((doc) => {
          let perfilData = doc.data();
          perfilData.id = doc.id;
          profiles.push(perfilData);
        });
        this.profiles = profiles;
      } catch (error) {
        console.log(error);
      }
    },

    handleSelected(selected: []) {
      this.selectedValue = selected;
    },

    confirmDelete() {
      this.selectedValue = [];
      this.getProfiles();
    },

    confirmAdd() {
      this.getProfiles();
    },

    confirmEdit() {
      this.getProfiles();
    },
  },
});
</script>
