<template>
  <div class="text-center">
    <v-btn
      v-if="eventsSelected.length > 0"
      icon
      v-on:click.prevent="exportExcel()"
    >
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <v-btn v-else disabled icon v-on:click.prevent="exportExcel()">
      <v-icon>mdi-download</v-icon>
    </v-btn>
  </div>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  props: {
    eventsSelected: {
      type: Array,
      required: true,
    },
  },

  methods: {
    exportExcel() {
      const data = this.ordenesTransformados;
      const ws = XLSX.utils.json_to_sheet(data);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Operaciones Registradas");
      XLSX.writeFile(wb, "Descargar_Informe_de_Registraciones.xlsx");
    },
  },

  computed: {
    ordenesTransformados() {
      return this.eventsSelected.map((event) => {
        // Redondear el total al entero más cercano
        const totalRedondeado = Math.round(event.amounts);

        // Formatear el total con el signo $ y separadores de miles
        //const totalFormateado = "$ " + totalRedondeado.toLocaleString("es-CL");

        return {
          ["Pais"]: event.country,
          ["Complejo"]: event.mall,
          ["Registrante"]: event.registrant,
          ["Local"]: event.shop,
          ["Contrato"]: event.code,
          ["Razon Social"]: event.tradename,
          ["Nombre Comercial"]: event.businessname,
          ["Fecha"]: event.end,
          ["Detalle"]: event.details,
          ["Operaciones"]: event.operations,
          ["Importe"]: totalRedondeado,
        };
      });
    },
  },
};
</script>
