<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="selectedItem.length > 0"
          color="success"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-else disabled icon v-bind="attrs" v-on="on">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar flat dark color="#463e3b">
          <v-card-title> Editar </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-form>
              <v-text-field
                type="text"
                label="* Codigo"
                v-model="code"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Descripción"
                v-model="description"
              ></v-text-field>
              <v-text-field
                type="text"
                label="* Abreviatura"
                v-model="abbreviation"
              ></v-text-field>

              <v-text-field
                type="text"
                label="* Habilitado"
                v-model="status"
              ></v-text-field>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog = false">Cancelar</v-btn>
          <v-btn class="white--text" color="#FD5C01" @click="submit"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar para mostrar el mensaje de usuario eliminado -->
    <v-snackbar
      v-model="snackbar"
      right
      :timeout="snackbarTimeout"
      color="success"
    >
      Perfil editado con éxito
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Emit, Watch } from "vue-property-decorator";

import { db } from "../../firebase";
//import firebase from "firebase/compat/app";

@Component
export default class EditComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedItem!: unknown[];

  // Atributos
  id = "";
  code = "";
  description = "";
  abbreviation = "";
  status = "";

  // Variables
  dialog = false;
  snackbar = false;
  snackbarTimeout = 3000;
  itemsStatus = ["SI", "NO"];

  async editUser(userId: string) {
    try {
      await db.collection("perfiles").doc(userId).set({
        code: this.code,
        description: this.description,
        abbreviation: this.abbreviation,
        status: this.status,
      });

      // Edita el Usuario en Firebase Auth

      // Emitir el evento de confirmación de eliminación exitosa
      this.emitConfirmEdit(true);

      this.snackbar = true;
      this.dialog = false;
    } catch (error) {
      console.log(error);
    }
  }

  submit() {
    this.editUser(this.id); // Llama al método addUser para enviar el formulario
  }

  // Emit que envía la confirmación de la agregación
  @Emit("confirmEdit")
  emitConfirmEdit(confirmEdit: boolean) {
    return confirmEdit;
  }

  @Watch("selectedItem")
  onSelectedChange() {
    // eslint-disable-next-line
    for (const item of this.selectedItem as any[]) {
      this.id = item.id;
      this.code = item.code;
      this.description = item.description;
      this.abbreviation = item.abbreviation;
      this.status = item.status;
    }
  }
}
</script>
