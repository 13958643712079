<template>
  <div class="text-center">
    <v-btn v-if="selectedItem.length > 0" icon v-on:click.prevent="exportPdf()">
      <v-icon>mdi-file-pdf-box</v-icon>
    </v-btn>
    <v-btn v-else disabled icon v-on:click.prevent="exportPdf()">
      <v-icon>mdi-file-pdf-box</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface Profile {
  code: string;
  description: string;
  abbreviation: string;
  status: string;
}

@Component
export default class DownloadComponent extends Vue {
  @Prop({ type: Array, required: true }) selectedItem!: Profile[];

  // Atributos
  code = "";
  description = "";
  abbreviation = "";
  status = "";

  //Methods
  exportPdf() {
    let docDefinition = {
      content: [
        { text: "Perfiles", style: "header" },
        {
          style: "tableExample",
          table: {
            widths: [50, "*", "*", 50],
            body: [
              [
                { text: "Código", style: "tableHeader" },
                { text: "Descripción", style: "tableHeader" },
                { text: "Abreviatura", style: "tableHeader" },
                { text: "Hábilitado", style: "tableHeader" },
              ],

              [this.code, this.abbreviation, this.description, this.status],
            ],
          },
          layout: "lightHorizontalLines",
        },
      ],
      pageOrientation: "landscape",
      pageMargins: [40, 40, 40, 40],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
      },
      defaultStyle: {
        fontSize: 8,
        //alignment: "justify",
      },
    };
    const pdf = pdfMake.createPdf(docDefinition);
    pdf.download("Dato_Perfil");
  }

  created() {
    this.updateProfileInfo();
  }

  get hasSelectedItem() {
    return this.selectedItem.length > 0;
  }

  updateProfileInfo() {
    if (this.hasSelectedItem) {
      const item = this.selectedItem[0];
      this.code = item.code;
      this.description = item.description;
      this.abbreviation = item.abbreviation;
      this.status = item.status;
    } else {
      // Si no hay usuario seleccionado, reiniciar valores
      this.resetProfileInfo();
    }
  }

  resetProfileInfo() {
    this.code = "";
    this.description = "";
    this.abbreviation = "";
    this.status = "";
  }

  @Watch("selectedItem")
  onSelectedUserChange(newVal: Profile[], oldVal: Profile[]) {
    if (newVal !== oldVal) {
      this.updateProfileInfo();
    }
  }
}
</script>
