<template>
  <v-container fluid>
    <!-- Seccion 1 -->
    <v-row dense class="ma-4">
      <v-col>
        <h1>Registración Mensual de Ventas</h1>
      </v-col>
    </v-row>
    <v-row dense justify="center" class="mx-4">
      <v-col>
        <v-text-field
          outlined
          label="Busqueda"
          v-model="search"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="9"></v-col>
    </v-row>
    <v-row dense justify="center" class="mx-4"
      ><v-col>
        <table-component
          :search="search"
          :contracts="contracts"
          @selected="handleSelected"
        ></table-component></v-col
    ></v-row>
    <!-- Seccion 2 -->
    <v-row dense justify="center" class="ma-4 section" id="calendar-section"
      ><v-divider></v-divider
    ></v-row>
    <v-row dense justify="center" class="mx-4">
      <v-col> <InfoComponent :selectedItem="selectedValue" /> </v-col>
      <v-col>
        <MonthlySalesRegistration
          :selectedItem="selectedValue"
          :calendarUpdate="calendarUpdate"
          @focus="handleFocus"
        />
      </v-col>
    </v-row>
    <v-row dense justify="center" class="ma-4" id="events-section"
      ><v-divider></v-divider
    ></v-row>
    <v-row class="ma-6"></v-row>
    <!-- Seccion 3 -->
    <v-row dense class="ma-4">
      <v-col>
        <h3>Operaciones Registradas</h3>
      </v-col>
    </v-row>
    <v-row dense justify="center" class="mx-4">
      <v-col>
        <v-text-field
          outlined
          label="Busqueda"
          v-model="searchOperation"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6"></v-col>

      <!-- Importar Excel -->
      <!-- <v-col cols="12" md="auto">
       <import-xls-component></import-xls-component>
      </v-col> -->

      <!-- Exportar Excel -->
      <!-- <v-col cols="12" md="auto">
         <export-xls-component
          :itemSelected="selectedValue"></export-xls-component>
      </v-col> -->

      <!-- Visualizar Componente -->
      <!-- <v-col cols="12" md="auto">
         <visualize-component
          :selectedItem="selectedValue"
        ></visualize-component> 
      </v-col> -->

      <!-- Descargar Pdf -->
      <!-- <v-col cols="12" md="auto">
        <download-component :selectedItem="selectedValue"></download-component> 
      </v-col> -->

      <v-col cols="auto" md="auto">
        <edit-operations-component
          :selectedItem="selectedValueOperation"
          @confirmEdit="confirmEdit"
        ></edit-operations-component>
      </v-col>

      <v-col cols="auto" md="auto">
        <delete-operations-component
          :selectedItem="selectedValueOperation"
          @confirmDelete="confirmDelete"
        ></delete-operations-component>
      </v-col>
      <v-col cols="12" md="auto" class="ma-1"> </v-col>

      <v-col cols="12" md="2">
        <add-operations-component
          :focus="focus"
          :selectedValue="selectedValue"
          :currentUser="currentUser"
          @confirmAdd="confirmAdd"
        ></add-operations-component>
      </v-col>
    </v-row>

    <v-row dense justify="center" class="pa-4">
      <v-col>
        <table-operations-component
          :search="searchOperation"
          :eventsFilter="eventsFilter"
          @selected="handleSelectedOperation"
        ></table-operations-component>
      </v-col>
    </v-row>
    <!-- <v-row>Elementos para Filtrar</v-row>
    <v-row>Contratos: {{ selectedValue }}</v-row>
    <v-row>Contrato Seleccionado Code: {{ selectedContractCode }}</v-row>
    <v-row>Calendario: {{ focus }}</v-row>
    <v-row>Eventos: {{ this.eventsFilter }}</v-row> -->
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import MonthlySalesRegistration from "../components/MonthlySalesRegistration/CalendarComponent.vue";
import InfoComponent from "../components/MonthlySalesRegistration/InfoComponent.vue";
import TableComponent from "../components/MonthlySalesRegistration/TableComponent.vue";
import TableOperationsComponent from "../components/MonthlySalesRegistration/TableOperationsComponent.vue";
import DeleteOperationsComponent from "../components/MonthlySalesRegistration/DeleteOperationsComponent.vue";
import EditOperationsComponent from "../components/MonthlySalesRegistration/EditOperationsComponent.vue";
import AddOperationsComponent from "../components/MonthlySalesRegistration/AddOperationsComponent.vue";
import { db } from "../firebase";

interface Contracts {
  id: string;
  code: string;
  tradename: string;
  identifier: string;
  businessname: string;
  shop: string;
  mall: string;
  country: string;
}

interface EventType {
  id: string;
  country: string;
  mall: string;
  registrant: string;
  shop: string;
  code: string;
  businessname: string;
  tradename: string;
  start: Date; //
  end: Date; //
  color: string;
  details: string; //
  operations: number; //
  amounts: number; //
}

interface User {
  code: string;
  names: string;
  contractsSelected: string[];
  // otras propiedades de currentUser si las hay
}

export default Vue.extend({
  name: "MonthlySalesRegistrationView",

  props: {
    currentUser: {
      type: Object as () => User,
      required: false,
      default: () => ({
        contractsSelected: [],
        names: "",
        code: "",
      }), // Valor predeterminado
    },
  },

  created() {
    // console.log(this.currentUser);
    // this.getContracts();

    if (this.currentUser && this.currentUser.contractsSelected) {
      this.currentUser.contractsSelected.forEach((contract: string) => {
        this.currentUserContracts.push(contract);
      });
    }
    this.getContracts();
  },

  data: () => ({
    contracts: [] as Contracts[],
    eventsFilter: [] as EventType[],
    selectedValue: [] as Contracts[],
    selectedValueOperation: [] as EventType[],
    focus: "",
    // selectedValueId: [] as any[],
    selectedValueId: "",
    search: "",
    searchOperation: "",
    currentUserContracts: [] as string[],
    calendarUpdate: false,
  }),

  components: {
    MonthlySalesRegistration,
    InfoComponent,
    TableComponent,
    TableOperationsComponent,
    DeleteOperationsComponent,
    EditOperationsComponent,
    AddOperationsComponent,
  },

  computed: {
    selectedContractCode(): string {
      return this.selectedValue.length > 0 ? this.selectedValue[0].code : "";
    },
  },

  watch: {
    currentUser: {
      handler() {
        if (this.currentUser && this.currentUser.contractsSelected) {
          this.currentUserContracts = [...this.currentUser.contractsSelected];
          this.getContracts(); // Actualiza los contratos cuando cambia currentUser
        }
      },
      deep: true,
    },
    focus(newFocus: string) {
      if (newFocus) {
        this.getEventsFilter();
      }
    },
  },

  methods: {
    // get Contratos
    async getContracts() {
      try {
        if (this.currentUserContracts.length === 0) {
          this.contracts = [];
          return;
        }
        //
        const batchedQueries = []; // Arreglo para almacenar las promesas de consultas por lotes
        const chunkSize = 30; // Tamaño máximo por lote

        for (let i = 0; i < this.currentUserContracts.length; i += chunkSize) {
          const chunk = this.currentUserContracts.slice(i, i + chunkSize); // Obtener el lote actual de hasta 30 elementos
          const query = db
            .collection("contratos")
            .where("code", "in", chunk)
            .get(); // Consultar Firebase
          batchedQueries.push(query); // Agregar la promesa al arreglo de consultas por lotes
        }

        // Ejecutar todas las consultas en paralelo y esperar a que todas se completen
        const snapshots = await Promise.all(batchedQueries);

        const contracts: Contracts[] = [];

        snapshots.forEach((snapshot) => {
          snapshot.forEach((doc) => {
            let perfilData = doc.data() as Contracts; // Asegurar que los datos son de tipo Contract
            perfilData.id = doc.id;
            contracts.push(perfilData);
          });
        });

        // eslint-disable-next-line
        this.contracts = contracts.sort((a: any, b: any) => {
          return parseInt(a.code, 10) - parseInt(b.code, 10);
        });

        // const snapshot = await db
        //   .collection("contratos")
        //   .where("code", "in", this.currentUserContracts) // Filtrar por códigos de contrato del currentUser
        //   .get();

        // // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // //const contracts: any = [];

        // snapshot.forEach((doc) => {
        //   let perfilData = doc.data();
        //   perfilData.id = doc.id;
        //   contracts.push(perfilData);
        // });
        // this.contracts = contracts;
      } catch (error) {
        console.log(error);
      }
    },

    // get Eventos Filtrados
    async getEventsFilter() {
      try {
        const snapshot = await db
          .collection("eventos")
          .where("code", "==", this.selectedContractCode)
          .where("start", "==", this.focus)

          .get();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const eventsFilter: any = [];

        snapshot.forEach((doc) => {
          let perfilData = doc.data();
          perfilData.id = doc.id;
          eventsFilter.push(perfilData);
        });
        this.eventsFilter = eventsFilter;
      } catch (error) {
        console.log(error);
      }
    },

    handleSelected(selected: Contracts[]) {
      this.selectedValue = [...selected];
      this.scrollToElement("calendar-section");
    },

    handleSelectedOperation(selected: EventType[]) {
      this.selectedValueOperation = [...selected];
    },

    handleFocus(focus: string) {
      this.focus = focus;
      this.selectedValueOperation = [];
      this.scrollToElement("events-section");
    },
    // Mueve el scroll
    scrollToElement(id: string) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    // Confirma Eliminar
    confirmDelete() {
      this.getEventsFilter(); // Actualiza los Eventos
      this.toggleCalendarUpdate(); // Llama a la función que actualiza el calendario
      this.selectedValueOperation = []; //Limpia los elementos seleccionados
    },
    // Confirma Agregar
    confirmAdd() {
      this.getEventsFilter();
      this.toggleCalendarUpdate();
      this.selectedValueOperation = [];
    },
    // Confirma Editar
    confirmEdit() {
      this.getEventsFilter();
      this.toggleCalendarUpdate();
      this.selectedValueOperation = [];
    },
    // Alterna el valor de calendarUpdate para asegurar la actualización
    toggleCalendarUpdate() {
      this.calendarUpdate = !this.calendarUpdate;
    },
  },
});
</script>

<style scoped>
.full-height {
  min-height: 100vh;
}
.section {
  margin-top: 64px; /* Ajusta esto según la altura de tu app-bar */
}
</style>
